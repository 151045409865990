import moneyIcon from "../../../../assets/img/icons/money.svg";
import { useFormSaver } from "../../../../hooks/useFormSaver";

import TipsImg from "../../../../assets/img/icons/tips.svg";
import bagIcon from "../../../../assets/img/icons/bag.svg";

import { Tooltip } from "../../../../components/Tooltip";
import CurrencyInput from "react-currency-input-field";

import { formatMoney } from "../../../../utils/format";
import { Button } from "../../../../components/Button";

import { FaExclamationCircle } from "react-icons/fa";
import parentStyles from "../../styles.module.scss";

import FilterOptionsModal from "./FilterOptionsModal";
import { BsQuestionCircle } from "react-icons/bs";

import { RiFileEditLine } from "react-icons/ri";
import styles from "./styles.module.scss";

import { useState } from "react"

const CampaignStep = ({ setStep, step, auth }) => {

  const [ isDisallowOperation, setIsDisallowOperation ] = useState(false);
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  
  const [ campaignCreationData, setCampaignCreationData ] = useFormSaver({
    name:"",
    amount:0,
    dailyMoney:0
  }, "tray-campaign-creation-data");

  const [ filter, setFilter ] = useFormSaver({ 
    type: "default", 
    operation: "allow" 
  }, "tray-campaign-creation-data-filter");

  const getFilterTags = () => {
    const MAX_TAGS_NUMBER = 10;
    let tags = [];

    const values = filter[filter.type] || [];

    for (let index = 0; index < values.length; index++) {
        if (index === MAX_TAGS_NUMBER) break;

        const value = values[index];
        tags.push(<li>{value.name}</li>)
    }

    if (values.length > MAX_TAGS_NUMBER) {
        tags.push(<li>+{values.length - MAX_TAGS_NUMBER}</li>)
    }

    return tags;
  }

  function handleOperation(active) {
    setIsDisallowOperation(!active);
    setFilter({ ...filter, operation: active ? "allow" : "disallow" })
  }

  return (
    <>
      <div className={ styles.container } id="campaign-step">
        
        <div>
          
          <div className={ parentStyles.box }>
            <RiFileEditLine 
              color="#6B7280"
              size={ 24 }
            />

            <strong>Campanha</strong>

            <label htmlFor="name">
              Nome da campanha
            </label>

            <div className={ styles.pmax_input_container }>
              <div>PMax Tray</div>
              <input
                onChange={ (e) => setCampaignCreationData({...campaignCreationData ,name:e.target.value}) }
                value={ campaignCreationData.name }
              />
            </div>

            <p className={ styles.pmax_example }>
              Exemplo: Roupas de Inverno; Campanha Black Friday.
            </p>
          </div>

          <div className={ parentStyles.box }>
            <img
              alt=""
              aria-hidden="true"
              src={ moneyIcon }
            />

            <strong>
              Investimento e saldo
            </strong>

            <div className={ styles.campaign_message }>
              <p>Defina o valor diário para investir em sua campanha</p>
              
              <Tooltip
                id="campaign__invest__tooltip"
                message="O algoritmo poderá investir mais em um dia quando identificar oportunidades de venda. O valor nunca será maior que o ‘orçamento mensal máximo’ ao lado."
              >
                <BsQuestionCircle 
                  color="#9CA3AF" 
                  size={ 20 }
                />
              </Tooltip>
            </div>

            
          <div className={ styles.campaign_range_container }>
            {campaignCreationData.amount < 35 ? <h4>Orçamento mínimo recomendado é de R$ 35,00</h4> : <></>}
            <input
              className={`slider ${campaignCreationData.amount < 35 ? "low-level" : "" }`}
              id="range"
              type="range"
              value={ campaignCreationData.amount }
              onChange={(event) => setCampaignCreationData({
                ...campaignCreationData, 
                dailyMoney:Number(event.target.value) * 100, 
                amount:Number(event.target.value)
              })}
              min="1"
              max="900"
              step="1"
            />
          </div>

          <div className={ styles.investment_value_container }>

            <div className={ styles.daily_currency_container }>
              <span class={styles.currency_sign}>R$</span>
              <CurrencyInput
                id="campaign__amountInvested--daily"
                name="campaign__amountInvested--daily"
                placeholder="Digite um valor"
                value={ campaignCreationData.amount }
                decimalsLimit={2}
                onValueChange={(value) => {
                    const currencyData = value ? Number(value.replace(",", ".")) : 0;
                    setCampaignCreationData({
                      ...campaignCreationData, 
                      dailyMoney:currencyData * 100, 
                      amount:Number(currencyData)
                    });
                }}
                decimalScale={2}
                step={1}
                maxLength={900}
                prefix=" "
                intlConfig={{ locale: "pt-BR", currency: "BRL" }}
              />
              <span className={ styles.daily_text }>média&nbsp;diária</span>
              <Tooltip
                id="campaign__invest__tooltip"
                message="O algoritmo poderá investir mais em um dia quando identificar oportunidades de venda. O valor nunca será maior que o ‘orçamento mensal máximo’ ao lado."
              >
                <FaExclamationCircle 
                  color="gray" 
                  size={ 20 } 
                  style={{ marginLeft:10 }}
                />
                {/* <img 
                  className={ styles.question_mark }
                  src={TipsImg} 
                  alt="Dicas" 
                  height={42} 
                  width={42} 
                /> */}
              </Tooltip>
            </div>

            <div className={ styles.monthly_ammout}>
              Orçamento mensal: <strong>{formatMoney(campaignCreationData.amount * 30)}</strong>
            </div>
          </div>
        </div>

        </div>

        <div>

          <div className={ parentStyles.box }>
            <img
              alt=""
              aria-hidden="true"
              src={ bagIcon }
            />
            <strong>Produtos</strong>
            <p>Selecione produtos, marcas ou categorias para incluir na campanha.</p>

            <div className={ styles.buttons_container }>
              <button 
                className={ filter.operation === "allow" ? styles.active : "" }
                onClick={ () => handleOperation(true) }
              >
                Inserir
              </button>
              <button 
                className={ filter.operation === "disallow" ? styles.active : "" } 
                onClick={ () => handleOperation(false) }
              >
                Restringir
              </button>
            </div>
            
            
            <>
              {isDisallowOperation ? (
                
                <div>
                  <label for="campaign__allow-products">Opções de restrição</label>
                  <select  value={filter.type} onChange={(e) => setFilter({ ...filter, type:e.target.value })}>
                      <option value="default">Nenhuma restrição</option>
                      <option value="product">Restringir produtos</option>
                      <option value="brand">Restringir marcas</option>
                      <option value="category">Restringir categorias</option>
                  </select>

                  <ul class={ styles.selected_products_list }>
                    {getFilterTags()}
                  </ul>
                </div>
              ) : (
                <div>
                    <label for="campaign__allow-products">Opções de seleção</label>
                    <select value={filter.type} onChange={(e) => setFilter({ ...filter, type:e.target.value })}>
                        <option value="default">Todos os produtos da loja</option>
                        <option value="product">Selecionar produtos</option>
                        <option value="brand">Selecionar marcas</option>
                        <option value="category">Selecionar categorias</option>
                    </select>

                    <ul class={ styles.selected_products_list }>
                        {getFilterTags()}
                    </ul>
                </div>
              )}
            </>

            {filter.type !== 'default' &&
              <button 
                onClick={() => setIsModalOpen(true)} 
                type="button" 
                class="edit-campaign__button blue"
              >
                { (filter.product && filter.product.length > 0)  ? "Editar seleção" : "Selecionar"}
              </button>
            }

            <div className={ styles.warn }>
              <FaExclamationCircle color="#6B7280" size={ 20 } />
              <p>As campanhas com maior retorno são aquelas com grande quantidade de produtos.</p>
            </div>

            <FilterOptionsModal
              campaign={filter}
              changeCampaignData={setFilter }
              handleClose={() => setIsModalOpen(!isModalOpen)}
              isOpen={isModalOpen}
            />

          </div>

        </div>

      </div>

      <div className={ parentStyles.line }/>
      
      <div className={ styles.next_button_container }>
        <Button onClick={() => setStep(step + 1)}>
          Continuar
        </Button>
      </div>
    </>
  )
}

export default CampaignStep;