import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../components/Button";
import { useAuth } from "../../hooks/useAuth";
import { useWizard } from "../../hooks/useWizard";
import { wizardSteps } from "../../contexts/WizardContext"
import { api } from "../../services/api";

import TipsImg from "../../assets/img/icons/tips.svg";
import GoogleAdImg from "../../assets/img/content/google-ad.png";
import { Tooltip } from "../Tooltip";

export function HasAccounts({ accounts = [] }) {
  const auth = useAuth();
  const wizard = useWizard();

  const [codeTerms, setCodeTerms] = useState(false);
  const [terms, setTerms] = useState(false);

  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [connectingAccount, setConnectingAccount] = useState(false);

  async function handleCreateAccount(event) {
    event.preventDefault();

    if (isCreatingAccount) return;

    try {
      setIsCreatingAccount(true);

      const response = await api.post("google/ads/customers");

      auth.changeGoogleAdsAccount({
        id: response.data.id,
        name: response.data.name,
        isNew: true
      });

      await api.post(
        `google/ads/customers/${response.data.id}/merchant_center_link`
      );

      await api.post(
        `google/ads/customers/${response.data.id}/set_gtag`
      );

      toast.success(`Conta Google Ads criada com sucesso.`);

      wizard.updateCurrentStep(wizardSteps.ACCOUNTS_CONNECTION_SUCCESS);
    } catch (err) {
      console.log(err);
      toast.error("Não foi possível criar a conta google ads, por favor tente novamente");
    } finally {
      auth.setIsNewGoogleAdsAccountCreated(true);
      setIsCreatingAccount(false);
    }
  }

  async function handleLinkAccount() {
    try {
      setConnectingAccount(true);

      await api.post(`google/ads/customers/${auth.googleAdsAccount?.id}/link`);

      await api.post(
        `google/ads/customers/${auth.googleAdsAccount?.id}/merchant_center_link`
      );

      await api.post(
        `google/ads/customers/${auth.googleAdsAccount?.id}/set_gtag`
      );

      toast.success(`Conta Google Ads conectada com sucesso.`);

      wizard.updateCurrentStep(wizardSteps.ACCOUNTS_CONNECTION_SUCCESS);
    } catch (err) {
      if (err?.response?.data?.error?.code === "4109") {
        toast.error(`Esta conta Google Ads não pode ser utilizada. Por favor, tente com outra conta ou crie uma nova.`);
      }

      if (err?.response?.data?.error?.code === "4110") {
        toast.error(`Esta conta Google Ads está desativada e não pode ser utilizada. Por favor, tente com outra conta ou crie uma nova.`);
      }

      if (err?.response?.data?.error?.code === "4111") {
        toast.error(`Para conectar esta conta, você deve aceitar o convite que foi enviado ao seu email.`);
      }
    } finally {
      setConnectingAccount(false);
    }
  }

  return (
    <div className="widget-products">
      <h1 className="widget-products__title"> Conectar uma conta Google Ads</h1>
      <p className="widget-products__text">
        Agora vamos conectar a sua conta Google Ads. <br />
        Ela permite que você crie {auth.campaignTypeNamePlural} no Google.
      </p>

      <div className="products">
        <div className="products__item--google-account">
          <div className="products__item products__item--google-account">
            <div className="products__item__img">
              <img src={GoogleAdImg} alt="Google Ads" />
            </div>

            <div className="products__item__user">
              <p>Nova Conta</p>
              <p>
                Google Ads
                <Tooltip id="mc_account" message="Conta que será utilizada para enviar o feed de produtos">
                  <img src={TipsImg} alt="Dicas" />
                </Tooltip>
              </p>
            </div>
          </div>

          <div>
            <select
              name="select-account"
              value={auth.googleAdsAccount?.id}
              onChange={(event) => {
                const account = accounts.find(a => a.id === event.target.value)
                auth.changeGoogleAdsAccount(account)
              }}
            >
              <option value="">Selecione uma conta</option>
              {accounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.name} - {account.id}
                </option>
              ))}
            </select>
            {!auth.isNewGoogleAdsAccountCreated &&
              <Link onClick={handleCreateAccount} to='/'>
                {isCreatingAccount ? "Criando nova conta..." : "Criar nova conta"}
              </Link>
            }
          </div>
        </div>
      </div>

      <p className="widget-products__description">
        O Google Ads tem como requisito a instalação de tags que irão rastrear
        as vendas do seu site e também criar listas de remarketing. <br />
        <br />A Tray vai fazer isso automaticamente por você, mas precisamos que
        conceda estas permissões e aceite os termos:
      </p>

      <div className="widget-products__confirm">
        <label className="control control--checkbox">
          <input
            type="checkbox"
            id="confirm"
            name="confirm"
            className="confirmed"
            checked={codeTerms}
            onChange={() => {
              setCodeTerms((oldVersion) => !oldVersion);
            }}
          />
          <div className="control__indicator"></div>

          <span>
            Concordo em instalar códigos que irão rastrear e integrar o meu
            site.
            <Tooltip id="codes" message="Estes códigos serão utilizados para melhorar suas campanhas e medir o resultado em vendas">
              <img src={TipsImg} alt="Dicas" />
            </Tooltip>
          </span>
        </label>

        <label className="control control--checkbox">
          <input
            type="checkbox"
            id="confirm2"
            name="confirm2"
            className="confirmed"
            checked={terms}
            onChange={() => {
              setTerms((oldVersion) => !oldVersion);
            }}
          />
          <div className="control__indicator"></div>
          <span>
            Ao contratar anúncios do Shopping, você concorda em cumprir os Termos e Políticas do Google, incluindo as{" "}
            <a target="_blank" href="https://support.google.com/merchants/answer/6149970?hl=pt-br">Políticas de Anúncios do Shopping</a> e os{" "}
            <a target="_blank" href="https://billing.google.com/payments/u/0/paymentsinfofinder?style=:md#">Termos e Condições do Google Ads</a>.
          </span>
          <Tooltip id="terms" message="Ao marcar que aceita você estará concordando com os termos dos serviços informados">
            <img src={TipsImg} alt="Dicas" />
          </Tooltip>
        </label>
      </div>

      <div className="account__form__item account__form__item--buttons">
        <Button onClick={() => wizard.updateCurrentStep(wizardSteps.PHONE_VERIFICATION)}>Voltar</Button>
        <Button
          disabled={
            !codeTerms ||
            !terms ||
            isCreatingAccount ||
            !auth.googleAdsAccount
          }
          isSubmitting={connectingAccount}
          submitingMessage="Conectando com a conta"
          onClick={handleLinkAccount}
        >
          Conectar conta Google Ads
        </Button>
      </div>
    </div>
  );
}
