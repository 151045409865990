export function replaceHttp(string) {
  if (!string) {
    return "";
  }

  let formated = string.replace("https", "");
  formated = formated.replace("http", "");
  formated = formated.replace(":", "");
  formated = formated.replaceAll("/", "");
  return formated;
}

export function formatMoney(value = 0) {
  const formattedValue = value.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  return formattedValue;
}

export function formatPhoneNumber(phoneNumber) {
  const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
  var str = String(phoneNumber).replace(/[^0-9]/g, "").slice(0, 11);

  return str.replace(regex, "($1) $2-$3");
}

export function formatRoas(value = 0) {
  value = Number(value);

  if (isNaN(value)) {
    return 0;
  }

  value = value.toFixed(2);
  return String(value).replace('.', ',');
}

export function formatConversions(value = 0, keepFixed = false) {
  value = Number(value);

  if (isNaN(value)) {
    return 0;
  }

  value = value.toFixed(2);

  if(keepFixed) return value;

  return String(value).replace('.', ',');
}

export function formatRoasPercentage(value = 0) {
  value = Number(value);

  if (isNaN(value)) {
    return 0;
  }

  value = (value - 1) * 100;

  value = value.toFixed(2);
  return String(value).replace('.', ',') + '%';
}

export function formatShortDate(dateStr) {
  let jsDate = new Date(dateStr);

  var year = jsDate.getFullYear();

  var month = (1 + jsDate.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = jsDate.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return day + '/' + month + '/' + year;
}

export function formatLongDate(dateStr) {
  let jsDate = new Date(dateStr);

  const day = jsDate.getDate();
  const month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][jsDate.getMonth()];
  const year = jsDate.getFullYear();

  return `${day} de ${month} de ${year}`
}

export function formatShortTime(dateStr) {
  let jsDate = new Date(dateStr);

  const hours = jsDate.getHours();
  let minutes = jsDate.getMinutes();

  if(minutes < 10) minutes = `0${minutes}`

  return `${hours}:${minutes}`
}