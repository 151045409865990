import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import Routes from "./routes/index.routes";
import { AuthProvider } from "./contexts/AuthContext";
import { GoogleAuthProvider } from './contexts/GoogleAuthContext';
import { ToastContainer } from "react-toastify";
import { WizardProvider } from './contexts/WizardContext';
import { NotificationProvider } from "./contexts/NotificationContext";
import { IconContext } from "react-icons";

import "swiper/swiper.scss";
import "./styles/app.css";
import "./styles/traygle.scss";
import "react-toastify/dist/ReactToastify.min.css";
import "./styles/toastify.scss";

function App() {
  return (
    <GTMProvider state={{ id: 'GTM-W9BVK5' }}>
      <AuthProvider>
        <WizardProvider>
          <GoogleAuthProvider>
            <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
              <ToastContainer
                autoClose={4000}
                closeButton={false}
                closeOnClick
                hideProgressBar
                pauseOnFocusLoss
                pauseOnHover
                position="top-center"
              />
              <NotificationProvider />
              <Routes />
            </IconContext.Provider>
          </GoogleAuthProvider>
        </WizardProvider>
      </AuthProvider>
    </GTMProvider>
  );
}

export default App;
