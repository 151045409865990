import { useState } from "react";
import { formatShortDate, formatShortTime } from "../../utils/format";

export default function LogItem({ log }) {
    const [showJson, setShowJson] = useState(false);
    const [showError, setShowError] = useState(false);

    let lastCell = <td>{log?.data?.message || '-'}</td>

    if (log?.data?.oldValue && log?.data?.newValue) {
        lastCell = <td>de '{log?.data?.oldValue}'<br />para '{log?.data?.newValue}'</td>
    }

    if (log?.data?.json || log?.data?.error) {
        lastCell = (
            <td class="json">
                {log?.data?.json &&
                    <div class={showJson ? 'showing' : ''}>
                        {!showJson && <button onClick={() => setShowJson(true)}>Ver arquivo json</button>}

                        {showJson &&
                            <>
                                <button onClick={() => setShowJson(false)}>Ocultar arquivo json</button>
                                <pre>{JSON.stringify(log?.data?.json, {}, 2)}</pre>

                            </>
                        }
                    </div>
                }

                {log?.data?.error &&
                    <div class={showError ? 'showing' : ''}>
                        {!showError && <button onClick={() => setShowError(true)}>Ver dados do erro</button>}

                        {showError &&
                            <>
                                <button onClick={() => setShowError(false)}>Ocultar dados do erro</button>
                                <pre>{JSON.stringify(log?.data?.error, {}, 2)}</pre>
                            </>
                        }
                    </div>
                }
            </td>
        )
    }

    return (
        <tr>
            <td>
                <p>{formatShortDate(log.createdAt)}</p>
                <p>{formatShortTime(log.createdAt)}</p>
            </td>
            <td>
                <p>{log.user}</p>
                <p>{log.ip}</p>
            </td>
            <td>{log.description}</td>
            {lastCell}
        </tr>
    )
}
