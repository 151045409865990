"use client";

import { useEffect, useState } from "react";

/**
 * Custom hook to save form values locally in the browser.
 * @param {any} initialValue - Initial value for the form.
 * @param {string} key - The key used to save and retrieve data from local storage.
 * @returns {[any, React.Dispatch<any>]} - Returns an array containing the form values and the function to set the form values.
 */
export function useFormSaver(initialValue, key) {
  const [ formValues, setFormValues ] = useState(initialValue);
  const [ firstTimeLoading, setFirstTimeLoading ] = useState(true); 

  useEffect(() => {
    const formData = getFormDataSavedLocally();
    if(formData) setFormValues(formData);
  }, []);

  useEffect(() => {
    if(firstTimeLoading) setFirstTimeLoading(false);
    else saveNewValues();
  }, [ formValues ]);

  function getFormDataSavedLocally() {
    const formDataStr = localStorage.getItem(key);
    if(!formDataStr) return;
    return JSON.parse(formDataStr);
  }

  function saveNewValues() {
    const formDataStr = JSON.stringify(formValues);
    localStorage.setItem(key, formDataStr);
  }

  return [ formValues, setFormValues ];
}