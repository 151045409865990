import { useEffect, useState } from "react";
import cx from "classnames";
import { FaExternalLinkAlt, FaRegMoneyBillAlt } from "react-icons/fa";

import { useAuth } from "../../hooks/useAuth";

import { Sidebar } from "../../components/Sidebar";
import { Button } from "../../components/Button";

import TipsImg from "../../assets/img/icons/tips.svg";
import styles from "./styles.module.scss";


function BudgetCard() {
    const auth = useAuth();
    const [ocid, setOcid] = useState(null);

    useEffect(() => {
        let invitationLink = auth.store?.google?.googleAdsInvitationLink;

        if (invitationLink && invitationLink.includes('ocid')) {
            const ocidFromUrl = new URLSearchParams(invitationLink).get("ocid");
            setOcid(ocidFromUrl);
        }
    }, []);

    return (
        <section
            className={cx("dashboard--states-dash-payed--aside-item", {
                "icon-green": true,
                "icon-yellow": false,
                "icon-red": false,
            })}
        >
            <div className="dashboard--states-dash-payed--aside-item-header">
                <span className="fs-0">
                    <FaRegMoneyBillAlt size={20} />
                </span>

                <p className={styles.budgetCardTitle}>Saldo da campanha</p>
            </div>

            {/* <aside className="dashboard--states-dash-payed--aside-item-aside end-box column-box">
                <p className="center-box mb-0.875">
                    Saldo restante
                    <Sidebar icon={<img src={TipsImg} alt="Dicas" />}>
                        <p>Dica</p>
                    </Sidebar>
                </p>
                <a href="#test">
                    Adicionar Saldo <FaExternalLinkAlt />
                </a>
            </aside>

            <footer className="dashboard--states-dash-payed--aside-item-footer">
                <p>
                    <span>
                        0%
                        <Sidebar icon={<img src={TipsImg} alt="Dicas" />}>
                            <p>Dica</p>
                        </Sidebar>
                    </span>

                    <span>500%</span>
                </p>

                <div>
                    <span></span>
                </div>
            </footer> */}

            <a
                className={styles.budgetCardButton}
                onClick={() => null}
                href={`https://ads.google.com/aw/billing/summary?${ocid ? `ocid=${ocid}` : ''}`}
                target="_blank"
            >
                Adicione ou confira o saldo
            </a>
        </section>
    );
}

export default BudgetCard;
