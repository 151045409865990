/* eslint-disable jsx-a11y/anchor-is-valid */
import { useHistory } from "react-router-dom";
import { RemoveCampaign } from "../RemoveCampaign";
import { FaTrash } from "react-icons/fa";

function RemoveSection() {
    const history = useHistory();

    return (
        <section class="cell small-12 edit-campaign__remove--section">
            <i><FaTrash /></i>
            <h3>Remover campanha</h3>
            <h4>Aviso: essa operação não poderá ser revertida</h4>

            <RemoveCampaign callback={() => history.push('/campaign')} />
        </section>
    );
}

export default RemoveSection;
