import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { api } from "../services/api";

export const WizardContext = React.createContext({});

export const wizardSteps = {
  START: "start",
  GOOGLE_ACCOUNT_CONNECTION: "google_account_connection",
  MERCHANT_CENTER_CONNECTION: "merchant_center_connection",
  PHONE_VERIFICATION: "phone_verification",
  GOOGLE_ADS_CONNECTION: "google_ads_connection",
  ACCOUNTS_REVIEW: "accounts_review",
  ACCOUNTS_CONNECTION_SUCCESS: "accounts_connection_success",
  SSC_PRESENTATION: "ssc_presentation",
  SSC_CREATION: "ssc_creation",
  COMPLETED: "completed",
};

export function WizardProvider({ children }) {
  const history = useHistory();
  const auth = useAuth();
  const [currentStep, setCurrentStep] = useState(wizardSteps.START);

  useEffect(() => {
    setCurrentStep(auth.currentWizardStep);

    if (auth.currentWizardStep === wizardSteps.COMPLETED && window.location.href.includes("wizard")) {
      history.push("/");
    }

    if (auth.currentWizardStep !== wizardSteps.COMPLETED && !window.location.href.includes("wizard")) {
      history.push("/wizard");
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentWizardStep]);

  const updateCurrentStep = (step) => {
    api.put(`platforms/tray/stores/${auth?.store?.id}`, {
      currentWizardStep: step,
    });

    setCurrentStep(step);
    auth.updateDataLayer(step);
  };

  const values = {
    currentStep,
    updateCurrentStep,
  };

  return <WizardContext.Provider value={values}>{children}</WizardContext.Provider>;
}
