import LoadSVG from "../../assets/img/icons/load.svg";

import styles from "./styles.module.scss";

export function Loading() {
  return (
    <div className={styles.contentContainer}>
      <img src={LoadSVG} alt="Loading animated icon" />
    </div>
  );
}
