import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { api } from "../../services/api";
import { Sidebar } from "../Sidebar";
import { toast } from "react-toastify";

import styles from "./styles.module.scss";

import SpinnerRedSvgGif from "../../assets/img/icons/spinner-red.svg";

export function PauseCampaign({ changeCampaignData, element }) {
  const auth = useAuth();
  const [isPausing, setIsPausing] = useState(false);

  async function handlePauseCampaign() {
    setIsPausing(true);
    if (
      auth.store === null ||
      !auth.store.google?.campaignId ||
      !auth.store.google?.googleAdsCustomerId
    ) {
      throw Error("Invalid data.");
    }

    const campaignId = auth.store.google.campaignId;
    const customerId = auth.store.google.googleAdsCustomerId;
    const data = {
      status: "PAUSED",
    };

    try {
      await api.put(
        `/google/ads/customers/${customerId}/campaigns/${campaignId}`,
        data
      );

      toast.success(`Campanha pausada com sucesso.`);
      changeCampaignData(data);
    } finally {
      setIsPausing(false);
    }
  }

  return (
    <Sidebar
      className={styles.settingsPauseCampaign}
      sidebarClass={styles.sidebar}
      icon={element}
    >
      {(props) => (
        <section className={styles.contentContainer}>
          <div className={styles.content}>
            <h2>Você tem certeza de que deseja pausar a campanha?</h2>

            <p>
              Atenção: O algoritmo poderá levar até 4 semanas para aprender a
              otimizar suas campanhas. Caso ela seja manualmente pausadas, este
              aprendizado será perdido.
            </p>
          </div>

          <div className={styles.buttons}>
            {isPausing ? (
              <button className="settings__button disconnect">
                <img src={SpinnerRedSvgGif} alt="Animation spinner" />{" "}
                Pausando...
              </button>
            ) : (
              <button
                onClick={() => props.handleCloseSidebar(handlePauseCampaign)}
                type="button"
                className="settings__button disconnect"
              >
                Pausar a campanha
              </button>
            )}

            <button
              onClick={() => props.handleCloseSidebar()}
              type="button"
              className="settings__maintainButton"
            >
              Manter a campanha ativa
            </button>
          </div>
        </section>
      )}
    </Sidebar>
  );
}
