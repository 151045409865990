import { IoMdCart } from "react-icons/io";
import { MdOutlineBlock } from "react-icons/md";
import { AiOutlineOrderedList } from "react-icons/ai";
import { FaArrowRight } from "react-icons/fa"
import { FaEdit } from "react-icons/fa";
import TipsImg from "../../assets/img/icons/tips.svg";
import { Tooltip } from "../../components/Tooltip";
import { useAuth } from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";

function ProductsStats() {
    const auth = useAuth();
    const history = useHistory();

    return (
        <main>
            <section className="grid-x dashboard__infos">
                <section className="cell small-12 large-3 dashboard__v-geral__item cost">
                    <aside className="fs-0">
                        <AiOutlineOrderedList size={16} />
                    </aside>

                    <main>
                        <h4>{auth.productsStats?.total}</h4>
                        <p>
                            Cadastrados{" "}
                            <Tooltip id="total_number" message="Produtos cadastrados no Google Merchant Center">
                                <img src={TipsImg} alt="Dicas" />
                            </Tooltip>
                        </p>
                    </main>
                </section>

                <section className="cell small-12 large-3 dashboard__v-geral__item clicks">
                    <aside className="fs-0">
                        <IoMdCart size={16} />
                    </aside>

                    <main>
                        <h4>{auth.productsStats?.active}</h4>
                        <p>
                            Ativos{" "}
                            <Tooltip id="active_number" message="Produtos habilitados para serem exibidos pelo Google">
                                <img src={TipsImg} alt="Dicas" />
                            </Tooltip>
                        </p>
                    </main>
                </section>

                <section className="cell small-12 large-3 dashboard__v-geral__item conversions-values">
                    <aside className="fs-0">
                        <FaEdit size={15} />
                    </aside>

                    <main>
                        <h4>{auth.productsStats?.pending}</h4>
                        <p>
                            Revisão pendente{" "}
                            <Tooltip id="pending_number" message="Revisão pendente do Google. Em breve o produto estará ativo ou, caso tenha algum problema, será reprovado.">
                                <img src={TipsImg} alt="Dicas" />
                            </Tooltip>
                        </p>
                    </main>
                </section>

                <section 
                    className="cell small-12 large-3 dashboard__v-geral__item conversions" 
                    onClick={() => history.push('/problems')}
                    style={{ cursor: 'pointer'}}
                >
                    <aside className="fs-0">
                        <MdOutlineBlock size={15} />
                    </aside>

                    <main style={{ position: "relative" }}>
                        <h4>{auth.productsStats?.disapproved}</h4>
                        <p>
                            Reprovados{" "}
                            <Tooltip id="disapproved_number" message="Produtos reprovados pelo Google que possuem um ou mais problemas a serem resolvidos. Você pode ver mais informações na aba relatório de produto.">
                                <img src={TipsImg} alt="Dicas" />
                            </Tooltip>
                        </p>
                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <FaArrowRight color="#008FFB" />
                        </div>
                    </main>
                </section>
            </section>
        </main>
    );
}

export default ProductsStats;
