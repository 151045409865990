import React, { useEffect, useState } from "react";
import nookies from "nookies";
import jwt from "jsonwebtoken";

import { useQuery } from "../hooks/useQuery";
import { api, setHeaderToken } from "../services/api";

import { wizardSteps } from "../contexts/WizardContext";

export const AuthContext = React.createContext({});

export function AuthProvider({ children }) {
  const query = useQuery();
  const [store, setStore] = useState(null);
  const [user, setUser] = useState(null);
  const [issues, setIssues] = useState({});
  const [productsStats, setProductsStats] = useState({});
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [merchantAccount, setMerchantAccount] = useState({});
  const [googleAdsAccount, setGoogleAdsAccount] = useState({});
  const [currentWizardStep, setCurrentWizardStep] = useState("");
  const [trayAdminUser, setTrayAdminUser] = useState(null);
  const [trayAdminUserId, setTrayAdminUserId] = useState(null);
  const [campaignType, setCampaignType] = useState("");
  const [campaignTypeName, setCampaignTypeName] = useState("");
  const [campaignTypeNamePlural, setCampaignTypeNamePlural] = useState("");
  const [allowMerchantCenterAccountCreation, setAllowMerchantCenterAccountCreation] = useState(false);
  const [isNewMerchantAccountCreated, setIsNewMerchantAccountCreated] = useState(false);
  const [isNewGoogleAdsAccountCreated, setIsNewGoogleAdsAccountCreated] = useState(false);
  const [traygleToken, setTraygleToken] = useState("");
  const [campaignBudget, setCampaignBudget] = useState("");

  useEffect(() => {
   
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [campaignBudget]);

  useEffect(() => {
    updateDataLayer();
  }, [currentWizardStep]);

  useEffect(() => {
    async function loadAccountData() {
      localStorage.clear();
      if (merchantAccount && merchantAccount.id) {
        try {
          const response = await api.get(`google/merchant_center/accounts/${merchantAccount?.id}`);
          setUser(response.data);

          const userPrimaryEmail = response.data.users.filter((e) => e.admin);

          setMerchantAccount({
            id: response.data.id,
            name: response.data.name,
          });

          // If the current merchant account were created by the application,
          // we do not allow the creation of a new account
          if (merchantAccount.id === store.google?.merchantId && store.google?.merchantCenterAccountOrigin === "created") {
            setIsNewMerchantAccountCreated(true);
          }

          if (userPrimaryEmail.length > 0) {
            // We can have other admin users that are not the current logged Google user. So we
            // need to check if the current logged Google user is on the userPrimaryEmail list
            // before taking the first user email.
            setPrimaryEmail(userPrimaryEmail.some((u) => u.emailAddress === primaryEmail) ? primaryEmail : userPrimaryEmail[0].emailAddress);
          }
        } catch {}
      }
    }
    loadAccountData();
  }, [merchantAccount?.id]);

  async function getCampaignBudget(clientCustomerId, campaignId) {
    try {
      const response = await api.get(`/google/ads/customers/${clientCustomerId}/campaigns/${campaignId}`);
      const budget = response.data.dailyAmountInCents;
      setCampaignBudget(budget);
      return budget;
    } catch (error) {
      console.error("Error fetching campaign budget:", error);
      return null;
    }
  }

  async function loadUser(token = null) {
    try {
      localStorage.clear();
      if (!token) {
        token = query.get("at");
      }

      if (token) {
        setTraygleToken(token);
        nookies.set(null, "@Traygle:token", token, {
          maxAge: 60 * 60 * 24,
        });
      } else {
        token = nookies.get(null)[`@Traygle:token`] || traygleToken;
      }

      if (token) {
        const decoded = jwt.decode(token);
        api.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };
        setHeaderToken(token);

        if (decoded?.trayAdminUser) {
          setTrayAdminUser(decoded?.trayAdminUser);
        }

        if (decoded?.trayAdminUserId) {
          setTrayAdminUserId(decoded?.trayAdminUserId);
        }

        const storeResponse = await api.get(`platforms/tray/stores/${decoded.storeId}`);
        setStore(storeResponse.data);

        if (storeResponse?.data?.currentWizardStep === wizardSteps.COMPLETED) {
          try {
            const [issuesResponse, statsResponse] = await Promise.all([api.get("/google/reports/issues"), api.get("/google/reports/products/stats")]);
            if (issuesResponse.data) setIssues(issuesResponse.data);
            if (statsResponse.data) setProductsStats(statsResponse.data);
          } catch {}
        }

        const userPrimaryEmail = storeResponse.data?.google?.email;
        if (userPrimaryEmail?.length > 0) {
          setPrimaryEmail(userPrimaryEmail);
        }

        const merchantId = storeResponse.data?.google?.merchantId;
        if (merchantId?.length > 0) {
          setMerchantAccount({
            id: merchantId,
          });
        }

        const googleAdsCustomerId = storeResponse.data?.google?.googleAdsCustomerId;
        if (googleAdsCustomerId?.length > 0) {
          setGoogleAdsAccount({
            id: googleAdsCustomerId,
          });
        }

        const campaignId = storeResponse.data?.google?.campaignId;
        let budget = null;
        if (campaignId && googleAdsCustomerId) {
          budget = await getCampaignBudget(googleAdsCustomerId, campaignId);
        }

        const wizardStep = storeResponse.data?.currentWizardStep || "";
        setCurrentWizardStep(wizardStep);

        //Datalayer preparation that came from url
        //try to get the values from localstorage, otherwise get from the url
        const dataLayerKey = "@Traygle:datalayer";
        const eventName = getEventNameFromWizardStep(wizardStep);
        let dataLayer = localStorage.getItem(dataLayerKey);

        if (!dataLayer || query.get("trayemail")) {
          dataLayer = {
            event: eventName,
            userEmail: query.get("trayemail"),
            googleEmailAccount: query.get("googleemail") || userPrimaryEmail,
            googleAdsId: query.get("googleadscustomerid") || googleAdsCustomerId,
            googleMechantId: query.get("merchantid") || merchantId,
            storeId: query.get("storeid") || decoded.storeId || store.id,
            budget: formatCurrency(budget),
          };

          localStorage.setItem(dataLayerKey, JSON.stringify(dataLayer));
        } else {
          dataLayer = JSON.parse(dataLayer);
        }
        //Set a timeout to prevent the datalayer to be sent before the user is fully loaded and trackings are ready
        setTimeout(() => {
          pushToDataLayer(dataLayer);
        }, 5000);
        //End datalayer preparation

        const storeCampaignType = storeResponse.data?.google?.campaignType;
        if (storeCampaignType === "pmax") {
          setCampaignType("pmax");
          setCampaignTypeName("campanha de Maior Desempenho");
          setCampaignTypeNamePlural("campanhas de Maior Desempenho");
        } else {
          setCampaignType("ssc");
          setCampaignTypeName("campanha Inteligente");
          setCampaignTypeNamePlural("campanhas Inteligentes");
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const updateDataLayer = (step) => {
    const eventName = getEventNameFromWizardStep(step);
    const formattedBudget = formatCurrency(campaignBudget);
    if (eventName) {
      const dataLayer = {
        event: eventName,
        userEmail: query.get("trayemail"),
        googleEmailAccount: query.get("googleemail") || primaryEmail,
        googleAdsId: query.get("googleadscustomerid") || googleAdsAccount.id,
        googleMechantId: query.get("merchantid") || merchantAccount.id,
        storeId: query.get("storeid") || store.id,
        budget: formattedBudget,
      };
      pushToDataLayer(dataLayer);
    }
  };

  const pushToDataLayer = (dataLayer) => {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayer);
    } catch (error) {
      console.error("Erro ao enviar evento para o dataLayer:", error);
    }
  };

  function changeMerchantAccount(account) {
    setMerchantAccount(account);
  }

  function changeGoogleAdsAccount(account) {
    setGoogleAdsAccount(account);
  }

  function changeSetCampaignBudget(budget) {
    setCampaignBudget(budget);
  }

  function getEventNameFromWizardStep(wizardStep) {
    const events = {
      [wizardSteps.GOOGLE_ACCOUNT_CONNECTION]: "google_account_connection",
      [wizardSteps.MERCHANT_CENTER_CONNECTION]: "merchant_center_connection",
      [wizardSteps.PHONE_VERIFICATION]: "phone_verification",
      [wizardSteps.GOOGLE_ADS_CONNECTION]: "google_ads_connection",
      [wizardSteps.ACCOUNTS_REVIEW]: "accounts_review",
      [wizardSteps.ACCOUNTS_CONNECTION_SUCCESS]: "accounts_connection_success",
      [wizardSteps.COMPLETED]: "completed",
      [wizardSteps.SSC_CREATION]: "ssc_creation",
      [wizardSteps.SSC_PRESENTATION]: "ssc_presentation",
      [wizardSteps.START]: "start",
    };
    return events[wizardStep] || "";
  }

  function formatCurrency(value) {
    const moneyValue = value / 100;
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(moneyValue);
  }

  const values = {
    issues,
    productsStats,
    store,
    user,
    hasUser: !!user,
    primaryEmail,
    currentWizardStep,
    trayAdminUser,
    trayAdminUserId,

    campaignType,
    campaignTypeName,
    campaignTypeNamePlural,

    loadUser,

    merchantAccount,
    changeMerchantAccount,

    googleAdsAccount,
    changeGoogleAdsAccount,

    allowMerchantCenterAccountCreation,
    setAllowMerchantCenterAccountCreation,

    isNewMerchantAccountCreated,
    setIsNewMerchantAccountCreated,

    isNewGoogleAdsAccountCreated,
    setIsNewGoogleAdsAccountCreated,

    traygleToken,
    setCampaignBudget,
    changeSetCampaignBudget,

    updateDataLayer,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
