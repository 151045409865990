import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";

import { Button } from "../../../../components/Button";
import Item from "./Item";
import styles from "./styles.module.scss";
import parentStyles from "../../styles.module.scss";
import { useState } from "react";

function PresentationStep({ setStep, step }) {
  const [currentOpen, setCurrentOpen] = useState(0);

  const imageBase = "campaign-ads";

  const data = [
    {
      title: "É hora de dar o próximo passo e criar sua campanha Performance Max.",
      subtitle: "Queremos facilitar esse processo para você.",
      text: "Estamos aqui para ajudar a maximizar seus resultados e alcançar seu público-alvo de maneira eficaz. Vamos criar algo incrível juntos!",
      imageIndex: 4,
    },
    {
      title: "Uma experiência ainda mais especial para novos anunciantes no Google Ads.",
      subtitle: "Gaste R$ 1.200,00 em até 60 dias e receba R$ 1.200,00 de volta na conta do Google Ads.",
      text: "Uma oportunidade única para impulsionar seus resultados e aumentar sua visibilidade online. Lembre-se de que o algoritmo leva cerca de 14 dias para atingir seu desempenho, então comece agora e aproveite ao máximo essa oferta incrível.",
      link: "https://www.google.com/intl/pt-br_br/ads/coupons/terms/",
      linkText: "Termos e Condições da oferta",
      imageIndex: 2,
    },
    {
      title: "Você não está sozinho nessa jornada!",
      subtitle: "Queremos compartilhar um case de sucesso que ilustra como a YoSports obteve benefícios significativos ao integrar seus produtos ao Google Ads.",
      text: "A experiência deles pode inspirar e guiar você nesse novo capítulo. Descubra como eles alcançaram novos patamares de visibilidade e sucesso com a nossa plataforma.",
      link: "https://www.tray.com.br/yosport/?utm_source=site&utm_medium=dobra-google&utm_campaign=case-yosport&utm_term=dobra-exclusiva-yosport",
      linkText: "Ver case YoSports",
      imageIndex: 3,
    },
  ];

  const hasNext = currentOpen < data.length - 1;
  const hasPrev = currentOpen > 0;
  const spheres = Array.from({ length: data.length }, (_, i) => i + 1);

  return (
    <div id="presentation-step" className={styles.presentation_container}>
      <div className={styles.carousel}>
        <button onClick={() => hasPrev && setCurrentOpen(currentOpen - 1)}>
          <MdKeyboardArrowLeft color="#6B7280" size={22} />
        </button>
        {data.map((item, index) => (
          <Item
            open={index === currentOpen}
            imageSrc={require(`../../../../assets/img/content/${imageBase}-${item.imageIndex}.png`).default}
            title={item.title}
            subtitle={item.subtitle}
            text={item.text}
            link={item.link}
            linkText={item.linkText}
            key={item.title}
          />
        ))}
        <button onClick={() => hasNext && setCurrentOpen(currentOpen + 1)}>
          <MdKeyboardArrowRight color="#6B7280" size={22} />
        </button>
      </div>
      <div className={styles.steps}>
        {spheres.map((_, i) => (
          <button className={currentOpen === i ? styles.active : ""} onClick={() => setCurrentOpen(i)} key={i} />
        ))}
      </div>
      <div className={parentStyles.line} />
      <div className={styles.next_button_container}>
        <Button onClick={() => setStep(step + 1)}>Criar Campanha</Button>
      </div>
    </div>
  );
}

export default PresentationStep;
