import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { api } from "../../services/api";
import { Sidebar } from "../Sidebar";
import { toast } from "react-toastify";

import styles from "./styles.module.scss";

import SpinnerRedSvgGif from "../../assets/img/icons/spinner-red.svg";

export function RemoveCampaign({ callback }) {
    const auth = useAuth();
    const [isRemovingCampaign, setIsRemovingCampaign] = useState(false);

    async function handleRemoveCampaign() {
        setIsRemovingCampaign(true);
        if (
            auth.store === null ||
            !auth.store.google?.campaignId ||
            !auth.store.google?.googleAdsCustomerId
        )
            return;
        const campaignId = auth.store.google.campaignId;
        const customerId = auth.store.google.googleAdsCustomerId;

        try {
            await api.delete(
                `/google/ads/customers/${customerId}/campaigns/${campaignId}`
            );

            auth.loadUser().then(() => {
                toast.success(`Campanha removida com sucesso.`);
            })
        } catch {
        } finally {
            setIsRemovingCampaign(false);
            callback();
        }
    }

    return (
        <Sidebar
            className={styles.settingsPauseCampaign}
            sidebarClass={styles.sidebar}
            icon={
                <button
                    type="button"
                    className="settings__button disconnect"
                    style={{width: 'fit-content'}}
                >
                    Remover campanha
                </button>
            }
        >
            {(props) => (
                <section className={styles.contentContainer}>
                    <div className={styles.content}>
                        <h2>Você tem certeza de que deseja remover a campanha?</h2>
                    </div>

                    <div className={styles.buttons}>
                        {isRemovingCampaign ? (
                            <button className="settings__button disconnect">
                                <img src={SpinnerRedSvgGif} alt="Animation spinner" />{" "}
                                Removendo...
                            </button>
                        ) : (
                            <button
                                onClick={() => props.handleCloseSidebar(handleRemoveCampaign)}
                                type="button"
                                className="settings__button disconnect"
                            >
                                Remover a campanha
                            </button>
                        )}

                        <button
                            onClick={() => props.handleCloseSidebar()}
                            type="button"
                            className="settings__maintainButton"
                        >
                            Cancelar
                        </button>
                    </div>
                </section>
            )}
        </Sidebar>
    );
}
