import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { useWizard } from "../../hooks/useWizard";
import { api } from "../../services/api";

import { Modal } from "../Modal";

import "./styles.scss";
import { wizardSteps } from "../../contexts/WizardContext";
import ReasonForm from "./reasonForm";
import DisconnectAccounts from "./disconnectAccounts";

export function DisconnectAccountsButtonWithResonForm({ forceRedirect }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {
        <DisconnectConfirmationModal
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          forceRedirect={forceRedirect}
        />
      }
      <button
        onClick={() => setIsModalOpen(true)}
        className="settings__button disconnect"
      >
        Desconectar contas
      </button>
    </>
  );
}

function DisconnectConfirmationModal({ isOpen, closeModal, forceRedirect }) {
  const auth = useAuth();
  const wizard = useWizard();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(0);

  const ModalData = modal === 0 ? ReasonForm : DisconnectAccounts;
  const [otherText, setOtherText] = useState("");

  const [reasons, setReasons] = useState();

  async function handleDisconnectAccount(campaingStatus) {
    if (loading) return;

    setLoading(true);
    try {
      const exitSurvey = { reasons };

      if (otherText) exitSurvey.other = otherText;
      
      wizard.updateCurrentStep(wizardSteps.START);
       await api.post("/google/accounts/unlink", {
        exitSurvey,
        campaignStatus: campaingStatus,
        campaignId:
          auth.store.google.campaignId !== ""
            ? auth.store.google.campaignId
            : "",
      });

      if (forceRedirect) {
        history.push("/wizard");
      }
    } catch {
    } finally {
      setLoading(false);
    }
  }

  function closeAndResetModal() {
    closeModal();
    setModal(0);
  }

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <ModalData
        hasGoogleAdsCampaignId={ !!auth.store.google.campaignId }
        close={closeAndResetModal}
        setModal={setModal}
        handleDisconnectAccount={handleDisconnectAccount}
        loading={loading}
        otherText={otherText}
        setOtherText={setOtherText}
        reasons={reasons}
        setReasons={setReasons}
      />
    </Modal>
  );
}
