import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Helmet from "react-helmet";
import { toast } from "react-toastify";

import { ProgressIndicator } from "../../../components/Wizard/ProgressIndicator";
import { useAuth } from "../../../hooks/useAuth";
import { useWizard } from "../../../hooks/useWizard";
import { wizardSteps } from "../../../contexts/WizardContext";

import SpinnerBlueSvgGif from "../../../assets/img/icons/spinner-blue.svg";
import GoogleImg from "../../../assets/img/content/google.png";
import SmallCheckImg from "../../../assets/img/icons/small-check.svg";
import MerchantImg from "../../../assets/img/content/merchant.png";
import GoogleAdImg from "../../../assets/img/content/google-ad.png";
import { Button } from "../../../components/Button";

import ResetModal from "./resetModal";
import { api } from "../../../services/api";
import { checkIfHasCampaignCreated } from "../../../utils/getCampaign";

export default function ConectedAccountsPage() {
  const auth = useAuth();
  const history = useHistory();
  const wizard = useWizard();
  const [isLoading, setIsLoading] = useState(false);

  const [modal, setModal] = useState(false);

  useEffect(() => {
    toast.success(`Embarque na plataforma finalizado.`);

    // setTimeout(() => {
    //   wizard.updateCurrentStep(wizardSteps.SSC_CREATION)
    // }, 5000)
  }, []);

  async function finishStep() {
    setIsLoading(true);

    // Check if has campaign created before
    // const createdCampaignsBefore = await checkIfHasCampaignCreated(auth);
    wizard.updateCurrentStep(wizardSteps.SSC_CREATION);

    // if (!createdCampaignsBefore) {
    //   history.push({ pathname: "/", state: { firstCampaign: true } });
    //   return;
    // }

    // history.push({ pathname: "/" });
    setIsLoading(false);
  }

  async function reset() {
    try {
      // await api.post("/google/accounts/unlink");
      wizard.updateCurrentStep(wizardSteps.GOOGLE_ACCOUNT_CONNECTION);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Helmet>
        <title>Verificar Telefone - Traygle</title>
        <meta name="description" content="Verifique o seu número de telefone." />
        <meta property="og:title" content="Verificar Telefone - Traygle" />
        <meta property="og:description" content="Verifique o seu número de telefone." />
        <script>
          {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://data.tray.com.br/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-W9BVK5');
          `}
        </script>
      </Helmet>

      <div className="accounts-created">
        <ResetModal isOpen={modal} closeModal={() => setModal(false)} reset={reset} />

        <ProgressIndicator currentStep={4} />

        <div className="widget-products widget-products--success">
          <h2 className="widget-products__title widget-products__title--success">Agora podemos criar a sua campanha.</h2>
          <p className="widget-products__text">Tudo certo, as suas contas foram conectadas com sucesso.</p>
          <div
            className="products"
            style={{
              maxWidth: "275px",
              margin: "0 auto",
              marginBottom: "2.375rem",
            }}
          >
            <div className="products__item">
              <div className="products__item__img">
                <img src={GoogleImg} alt="Google" />
              </div>
              <div className="products__item__user">
                <p>Sua conta Google:</p>
                <p>
                  {auth.primaryEmail}
                  <span data-open="modal-help">
                    <img src={SmallCheckImg} alt="Autenticado" />
                  </span>
                </p>
              </div>
            </div>

            <div className="products__item">
              <div className="products__item__img">
                <img src={MerchantImg} alt="Merchant" />
              </div>
              <div className="products__item__user">
                <p>Conta Merchant Center:</p>
                <p>
                  {auth.merchantAccount?.name} - {auth.merchantAccount?.id}
                  <span>
                    <img src={SmallCheckImg} alt="Autenticado" />
                  </span>
                </p>
              </div>
            </div>

            <div className="products__item--google-account">
              <div className="products__item products__item--google-account">
                <div className="products__item__img">
                  <img src={GoogleAdImg} alt="Google Ads" />
                </div>
                <div className="products__item__user">
                  <p>Conta Google Ads</p>
                  <p>
                    {auth.googleAdsAccount?.name} - {auth.googleAdsAccount?.id}
                    <span>
                      <img src={SmallCheckImg} alt="Autenticado" />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="widget-buttons_container">
            <Button className="widget-buttons_container-button__rest" onClick={() => setModal(true)}>
              Reiniciar Integração
            </Button>

            <Button className="widget-buttons_container-button__main" onClick={finishStep} isSubmitting={isLoading}>
              Confirmar contas
            </Button>
          </div>

          {/* <p className="widget-products__text">
            <img src={SpinnerBlueSvgGif} alt="Animation spinner" height={25} width={25}/>{" "}
            Aguarde...
          </p> */}
        </div>
      </div>
    </>
  );
}
