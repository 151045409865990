import React, { useState } from "react";
import { MissingScopesModal } from "../components/GoogleAuthModals/MissingScopesModal";
import { ScopesInfoModal } from "../components/GoogleAuthModals/ScopesInfoModal";

import { useAuth } from "../hooks/useAuth";
import { useWizard } from "../hooks/useWizard";
import { api } from "../services/api";
import { wizardSteps } from "./WizardContext";

export const GoogleAuthContext = React.createContext({});

export function GoogleAuthProvider({ children }) {
  const auth = useAuth();
  const wizard = useWizard();

  const [ isScopesInfoModalOpen, setIsScopesInfoModalOpen ] = useState(false);
  const [ isMissingScopesModalOpen, setIsMissingScopesModalOpen ] = useState(false);

  const [ hideGoogleModal, setHideGoogleModal ] = useState(false);
  const [missingScopes, setMissingScopes] = useState([]);

  async function startGoogleAuth() {
    try {
      const response = await api.get("google/auth", {
        params: {
          storeId: auth.store.id,
          platformId: "tray",
        },
      });

      const popup = showGoogleAuthPopUp(response.data.redirectUrl);

      let currentAttempt = 0;
      const maxAttempts = 360; // 360 attempts x 1 second = 6 minutes

      const interval = setInterval(async () => {
        // We need to check the popup url inside a try-catch
        // to avoid an cross-domaing exception
        try {
          if (currentAttempt > maxAttempts || popup.closed) {
            clearInterval(interval);
          }

          const popupHref = popup?.location?.href || "";

          if (
            popupHref.includes("auth_success") ||
            window.location.href.includes("auth_success")
          ) {
            let popupUrl = popup.location.search;
            let urlParams = new URLSearchParams(popupUrl);
            let token = urlParams.get("at");

            if (!token) {
              popupUrl = window.location.search;
              urlParams = new URLSearchParams(popupUrl);
              token = urlParams.get("at");
            }

            popup.close();
            setIsScopesInfoModalOpen(false);
            setIsMissingScopesModalOpen(false);

            auth.loadUser(token).then(() => {
              clearInterval(interval);
              wizard.updateCurrentStep(wizardSteps.MERCHANT_CENTER_CONNECTION);
            });
          }

          if (
            popupHref.includes("missing_scopes") ||
            window.location.href.includes("missing_scopes")
          ) {
            let missingScopesFromBackend = new URLSearchParams(
              popup.location.search
            ).get("missing_scopes");

            if (!missingScopesFromBackend) {
              missingScopesFromBackend = new URLSearchParams(
                window.location.search
              ).get("missing_scopes");
            }

            setMissingScopes(missingScopesFromBackend.split(","));

            popup.close();
            setIsScopesInfoModalOpen(false);
            setIsMissingScopesModalOpen(true);
          }

          if (popupHref.includes("access_denied")) {
            popup.close();
          }

          currentAttempt += 1;
        } catch {}
      }, 1000);

    } catch {}
  }

  function showGoogleAuthPopUp(url) {
    // Calculate popUp size
    var h = Math.max(800, window.screen.availHeight * 0.66); // try to use 66% of height, but no smaller than 800
    var w = Math.max(500, window.screen.availWidth * 0.25); // try to use 25% of width, but no smaller than 800

    // Find popUp center
    var windowLocation = {
      left: window.screen.availLeft + window.screen.availWidth / 2 - w / 2,
      top: window.screen.availTop + window.screen.availHeight / 2 - h / 2,
    };

    const config =
      "ModalPopUp" +
      ", toolbar=no" +
      ", scrollbars=no," +
      ", location=no" +
      ", statusbar=no" +
      ", menubar=no" +
      ", resizable=0" +
      ", width=" +
      w +
      ", height=" +
      h +
      ", left=" +
      windowLocation.left +
      ", top=" +
      windowLocation.top;

    return window.open(url, "Google Ads", config);
  }

  function showScopesInfoModal() {
    setIsScopesInfoModalOpen(true);
  }

  function openOnlyGoogle() {
    return setHideGoogleModal(true);
  }

  const values = { startGoogleAuth, showScopesInfoModal, openOnlyGoogle };

  return (
    <GoogleAuthContext.Provider value={values}>
      <div>
        {children}
        <ScopesInfoModal
          isOpen={isScopesInfoModalOpen}
          closeModal={() => setIsScopesInfoModalOpen(false)}
          onGoogleSignInButtonClick={startGoogleAuth}
          hidden={ hideGoogleModal }
        />
        <MissingScopesModal
          isOpen={isMissingScopesModalOpen}
          closeModal={() => setIsMissingScopesModalOpen(false)}
          missingScopes={missingScopes}
          onGoogleSignInButtonClick={startGoogleAuth}
        />
      </div>
    </GoogleAuthContext.Provider>
  );
}
