
import { useCloseable } from "../../hooks/useIsClosable";
import phone from "../../assets/img/icons/phone.svg";

import { useAuth } from "../../hooks/useAuth";
import { IoMdClose } from "react-icons/io";

import { Link } from "react-router-dom";


export default function Navigation({ currentSection }) {
  const auth = useAuth();
  const [isClosed, close] = useCloseable("tray-traygle-google-0800-box", false);

  return (
    <>
      <div class="google-ads-title-container">
        <p>Marketing</p>
        <h2>Google Ads</h2>
      </div>

      {!isClosed && 
        <div class="default-message">
          <div class="default-message__icon">
            <img
              alt=""
              aria-hidden="true"
              src={phone}
            />
          </div>

          <div>
            <strong>Tem dúvidas sobre a integração do Google?</strong>
            <p>Ligue para <strong>0800 724 8567</strong> para falar com um especialista do Google Ads.</p>
          </div>
          
          <button onClick={() => close()}>
            <IoMdClose 
              color="#374151"
              className="default-message__close-icon opacity-effect"
              size={20}
            />
          </button>
        </div>
      }
      
      <ul className="dashboard__nav">
        <li className={currentSection === "main" ? "active" : ""}>
          <Link to="/">Visão Geral</Link>
        </li>
        <li className={currentSection === "campaign" ? "active" : ""}>
          <Link to="/campaign">Campanha</Link>
        </li>
        <li className={currentSection === "reports" ? "active" : ""}>
          <Link to="/reports">Relatório de Produto</Link>
        </li>
        <li className={currentSection === "problems" ? "active" : ""}>
          <Link to="/problems">
            Problemas
            <span>
              {auth.issues && auth.issues?.productLevelIssues?.itemLevelIssues ? auth.issues && auth.issues?.productLevelIssues?.itemLevelIssues?.length + auth?.issues?.accountLevelIssues?.length : 0}
            </span>
          </Link>
        </li>
        <li className={currentSection === "settings" ? "active" : ""}>
          <Link to="/settings?tab=accounts">Configurações</Link>
        </li>
      </ul>
    </>
  );
}
