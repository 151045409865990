import { useState } from "react";
import { toast } from "react-toastify";

import { Button } from "../../components/Button";
import { useAuth } from "../../hooks/useAuth";
import { useWizard } from "../../hooks/useWizard";
import { wizardSteps } from "../../contexts/WizardContext"
import { api } from "../../services/api";

import { OverrideSiteVerification } from "./OverrideSiteVerification";
import { OverrideSiteClaiming } from "./OverrideSiteClaiming";
import { DisconnectAccountsButton } from "../DisconnectAccountsButton";

export function NoAccount() {
  const auth = useAuth();
  const wizard = useWizard();

  const [accptedTerms, setAccptedTerms] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  const [showOverrideSiteClaiming, setShowOverrideSiteClaiming] = useState(false);

  const [currentVerifiedWebSite, setCurrentVerifiedWebSite] = useState('');
  const [showOverrideSiteVerification, setShowOverrideSiteVerification] = useState(false);

  async function handleCreateAndLinkAccount() {
    try {
      setIsCreatingAccount(true);

      const response = await api.post("google/merchant_center/accounts");
      const merchantId = response.data.id
      auth.changeMerchantAccount({
        id: response.data?.id,
        name: response.data?.name
      });

      await api.post(
        `google/merchant_center/accounts/${merchantId}/verify_website`,
        {
          overwrite: false,
        }
      );

      await api.post(
        `google/merchant_center/accounts/${merchantId}/claim_website`,
        {
          overwrite: false,
        }
      );

      wizard.updateCurrentStep(wizardSteps.PHONE_VERIFICATION);
    } catch (err) {
      if (err?.response?.data?.error?.code === "4204") {
        setShowOverrideSiteClaiming(true);
      }

      if (err?.response?.data?.error?.code === "4205") {
        setCurrentVerifiedWebSite(err?.response?.data?.error?.websiteUrl);
        setShowOverrideSiteVerification(true);
      }

      if (err?.response?.data?.error?.code === "4208") {
        toast.error('O email da sua conta Google está associado a uma conta mestre do Merchant Center e não pode ser utilizado.');
      }

      if (err?.response?.data?.error?.code === "4210") {
        toast.error('Não é possível reivindicar o site porque a sua URL ainda não foi verificada.');
      }

      if (err?.response?.data?.error?.code === "4211") {
        toast.error('Não foi possível verificar o site da sua loja. Acesse a sua conta do Merchant Center para realizar a verificação.');
      }

      if (err?.response?.data?.error?.code === "4213") {
        toast.error('Uma conta do Merchant Center já foi criada anteriormente. Selecione uma das contas já existentes para continuar a configuração.');
      }
    } finally {
      auth.setIsNewMerchantAccountCreated(true);
      setIsCreatingAccount(false);
    }
  }

  async function handleOverrideSiteVerification() {
    try {
      setShowOverrideSiteVerification(false);
      setIsCreatingAccount(true);

      await api.post(
        `google/merchant_center/accounts/${auth.merchantAccount?.id}/verify_website`,
        {
          overwrite: true,
        }
      );

      await api.post(
        `google/merchant_center/accounts/${auth.merchantAccount?.id}/claim_website`,
        {
          overwrite: false,
        }
      );

      wizard.updateCurrentStep(wizardSteps.PHONE_VERIFICATION);
    } catch (err) {
      if (err?.response?.data?.error?.code === "4204") {
        setShowOverrideSiteClaiming(true);
      }

      if (err?.response?.data?.error?.code === "4205") {
        setCurrentVerifiedWebSite(err?.response?.data?.error?.websiteUrl);
        setShowOverrideSiteVerification(true);
      }

      if (err?.response?.data?.error?.code === "4210") {
        toast.error('Não é possível reivindicar o site porque a sua URL ainda não foi verificada.');
      }

      if (err?.response?.data?.error?.code === "4211") {
        toast.error('Não foi possível verificar o site da sua loja. Acesse a sua conta do Merchant Center para realizar a verificação.');
      }
    } finally {
      setIsCreatingAccount(false);
    }
  }

  async function handleOverrideSiteClaiming() {
    try {
      setShowOverrideSiteClaiming(false);
      setIsCreatingAccount(true);

      await api.post(
        `google/merchant_center/accounts/${auth.merchantAccount?.id}/claim_website`,
        {
          overwrite: true,
        }
      );

      wizard.updateCurrentStep(wizardSteps.PHONE_VERIFICATION);
    } catch (err) {
      if (err?.response?.data?.error?.code === "4204") {
        setShowOverrideSiteClaiming(true);
      }

      if (err?.response?.data?.error?.code === "4209") {
        toast.error('Não é possível reivindicar o site para a conta Merchant Center selecionada. O site está reivindicado por outra conta sob a qual o nosso sistema não possui permissões para editar. Por favor, escolha outra conta do Merchant Center ou crie uma nova.');
      }

    } finally {
      setIsCreatingAccount(false);
    }
  }

  return (
    <div className="widget">
      <h1 className="widget__title widget__title--google">
        Criar uma conta Merchant Center.
      </h1>

      <div className="widget__text--google">
        A sua conta Google foi configurada com sucesso. <br />
        Agora vamos conectar a sua conta Google Merchant Center. <br />
        Ela permite que os seus produtos apareçam na busca Google.
      </div>

      <div className="widget__terms--google">
        <label className="control control--checkbox center-box">
          <input
            disabled={isCreatingAccount}
            type="checkbox"
            id="confirm2"
            name="confirm2"
            className="confirmed"
            checked={accptedTerms}
            onChange={() => {
              !isCreatingAccount &&
                setAccptedTerms((oldVersion) => !oldVersion);
            }}
          />
          <div className="control__indicator"></div>
          <span>
            Aceito os{" "}
            <a target="_blank" href="https://merchants.google.com/files/tos/extend/BR/tos.html">Termos de Serviço do Google Merchant Center</a>.
          </span>
        </label>
      </div>

      <div className="widget__connect__buttons widget__connect__buttons--connect text-center">
        {!isCreatingAccount && <DisconnectAccountsButton />}
        <Button
          disabled={!accptedTerms}
          isSubmitting={isCreatingAccount}
          onClick={handleCreateAndLinkAccount}
          submitingMessage="Criando conta"
        >
          Criar conta Merchant Center
        </Button>
      </div>

      <OverrideSiteVerification
        currentVerifiedWebSite={currentVerifiedWebSite}
        setShowOverrideSiteVerification={setShowOverrideSiteVerification}
        showOverrideSiteVerification={showOverrideSiteVerification}
        handleOverrideSiteVerification={handleOverrideSiteVerification}
      />

      <OverrideSiteClaiming
        setShowOverrideSiteClaiming={setShowOverrideSiteClaiming}
        showOverrideSiteClaiming={showOverrideSiteClaiming}
        handleOverrideSiteClaiming={handleOverrideSiteClaiming}
      />
    </div>
  );
}
