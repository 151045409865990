import { useState } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "../Button";
import { Sidebar } from "../Sidebar";
import { useAuth } from "../../hooks/useAuth";
import { useWizard } from "../../hooks/useWizard";
import { wizardSteps } from "../../contexts/WizardContext"

import { api } from "../../services/api";

import styles from "./existingCampaignModal.module.scss";
import { toast } from "react-toastify";

export function ExistingCampaignModal({
    campaign,
    setShowExistingCampaignModal,
    showExistingCampaignModal
}) {
    const auth = useAuth();
    const history = useHistory();
    const wizard = useWizard();

    const [accepted, setAccepted] = useState(false);
    const [loading, setLoading] = useState(false);

    const integrateCampaign = async () => {
        setLoading(true);

        try {
            await api.put(`platforms/tray/stores/${auth?.store?.id}`, {
                google: { campaignId: campaign.id }
            });

            auth.loadUser().then(() => {
                toast.success(`Campanha conectada com sucesso.`);

                wizard.updateCurrentStep(wizardSteps.COMPLETED);
                history.push({ pathname: "/", state: { showWelcomeModal: true } })
            })
        } catch {
        } finally {
            setLoading(false);
        }
    }

    return (
        <Sidebar
            isSidebarOpen={showExistingCampaignModal}
            onOpen={() => setShowExistingCampaignModal(true)}
            onClose={() => null}
            className={styles.existingCampaignModalContentContainer}
            sidebarClass={styles.sidebar}
        >
            <h4 className={styles.title}>
                Você já possui a {auth.campaignTypeName} <span>{campaign.name}</span>.
            </h4>

            <p className={styles.description}>
                Não tem problema!<br />
                Podemos gerenciar a sua campanha conectando-a à sua loja Tray.
            </p>

            <div className={styles.buttonsAndCheckbox}>
                <label className="control control--checkbox center-box">
                    <input
                        type="checkbox"
                        id="confirm"
                        name="confirm"
                        className="confirmed"
                        checked={accepted}
                        onChange={() => {
                            setAccepted((oldVersion) => !oldVersion);
                        }}
                    />
                    <div className="control__indicator"></div>

                    <span>
                        Entendo que a minha {auth.campaignTypeName} será conectada à Tray.
                    </span>
                </label>

                <Button
                    className={styles.acceptConfiguration}
                    disabled={!accepted}
                    onClick={integrateCampaign}
                    isSubmitting={loading}
                    submitingMessage="Conectando campanha..."
                >
                    Conectar minha campanha com a Tray
                </Button>
                <Button
                    className={styles.cancelConfiguration}
                    onClick={() => wizard.updateCurrentStep(wizardSteps.GOOGLE_ADS_CONNECTION)}
                >
                    Escolher outra conta Google Ads
                </Button>
            </div>
        </Sidebar>
    );
}
