import { getCampaign } from "../../../../utils/getCampaign";
import { useAuth } from "../../../../hooks/useAuth";

import { api } from "../../../../services/api";
import { toast } from "react-toastify";

import { useEffect } from "react";

const UpdateCampaign = ({ endLoading, filter, returnToCampaignPage, campaignCreated }) => {
  const auth = useAuth();

  useEffect(() => {
    const updateCampaign = async () => {
      const campaignId = await getCampaign(auth.store.id);
      if (auth.store === null || !campaignId || !auth.store.google?.googleAdsCustomerId) {
        throw Error("Invalid data.");
      }

      const customerId = auth.store.google.googleAdsCustomerId;

      try {
        await api.post(`/google/ads/customers/${customerId}/campaigns/${campaignId}/filter`, filter);

        localStorage.removeItem("tray-campaign-creation-data");
        localStorage.removeItem("tray-campaign-creation-data-filter");

        toast.success(`Campanha criada com sucesso.`);

        returnToCampaignPage();
        // await history.push({ pathname: "/campaign", state: { showWelcomeModal: true } });
      } catch (err) {
        console.log(err);
      } finally {
        endLoading(false);
      }
    };

    if (campaignCreated) updateCampaign();
  }, [campaignCreated]);

  return <></>;
};

export default UpdateCampaign;
