import { useEffect, useState } from "react";
import Normal from "./Normal";
import Free from "./Free";
import nookies from "nookies";

import { useAuth } from "../../hooks/useAuth";
import { Loading } from "../../components/Loading";
import { WelcomeModal } from "../../components/WelcomeModal";

import { useHistory } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { checkIfHasCampaignCreated } from "../../utils/getCampaign";

export default function Dashboard() {
  const auth = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [redirectToPmaxWasChecked, setRedirectToPmaxWasChecked] = useState(false);

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(location?.state?.showWelcomeModal);

  const redirectToPmax = async () => {
    const createdCampaignsBefore = await checkIfHasCampaignCreated(auth);

    // if (!createdCampaignsBefore) {
    //   history.push({ pathname: "/pmax", state: { firstCampaign: true } });
    //   return;
    // }
    setRedirectToPmaxWasChecked(true);
    localStorage.setItem("traygle-has-created-campaign-" + auth?.store?.id, true);
  };

  //Cleaning all saved data when user enters at pmax page
  useEffect(() => {
    return () => {
      localStorage.removeItem("tray-campaign-creation-data");
      localStorage.removeItem("tray-campaign-creation-data-filter");
    };
  }, []);

  useEffect(() => {
    // checking if user has a campaign created when enters at dashboard
    if (auth?.store?.id && !localStorage.getItem("traygle-has-created-campaign-" + auth?.store?.id)) {
      redirectToPmax();
    } else {
      setRedirectToPmaxWasChecked(true);
    }
  }, [auth]);

  if (auth.store === null) return <Loading />;
  const hasCampaign = auth.store.google?.campaignId;

  if (!redirectToPmaxWasChecked) return <Loading />;

  return (
    <>
      {hasCampaign ? <Normal /> : <Free />}
      <WelcomeModal isOpen={isWelcomeModalOpen} closeModal={() => setIsWelcomeModalOpen(false)} />
    </>
  );
}
