const siblingsCount = 1;

function generatePagesArray(from, to) {
  return [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter((page) => page > 0);
}

export function Pagination({ currentPage, onPageChange, hasNextPage, lastPage, showFirstAndLastPageArrows }) {
  lastPage = lastPage || 1;

  const previousPages =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : [];

  const nextPages =
    hasNextPage
      ? generatePagesArray(currentPage, Math.min(currentPage + siblingsCount, lastPage))
      : [];

  return (
    <div className="pages">
      {currentPage > 1 && (
        <>
          {showFirstAndLastPageArrows &&
            <button onClick={() => onPageChange(1)}>«</button>
          }
          <button onClick={() => onPageChange(currentPage - 1)}>&#8249;</button>
        </>
      )}

      {previousPages.length > 0 &&
        previousPages.map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className="page-item"
          >
            {page}
          </button>
        ))}

      <button
        onClick={() => onPageChange(currentPage)}
        className="page-item active"
      >
        {currentPage}
      </button>

      {nextPages.length > 0 &&
        nextPages.map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className="page-item"
          >
            {page}
          </button>
        ))}

      {hasNextPage && (
        <>
          <button onClick={() => onPageChange(currentPage + 1)}>&#8250;</button>
          {showFirstAndLastPageArrows &&
            <button onClick={() => onPageChange(lastPage)}>»</button>
          }
        </>
      )}
    </div>
  );
}
