import CampaignsListingSection from "../../components/SettingsPage/CampaignsListingSection";
import CampaingCreationCallToAction from "../../components/CampaingCreationCallToAction";

import Navigation from "../../components/Dashboard/Navigation";
import { useAuth } from "../../hooks/useAuth";

import { useEffect } from "react";
import Helmet from "react-helmet";

export const Campaign = () => {
  const auth = useAuth();

  return (
    <>
      <Helmet>
        <title>Dashboard - Campanhas</title>
        <meta name="description" content="Dashboard Traygle" />
        <meta property="og:title" content="Dashboard - Traygle" />
        <meta property="og:description" content="Dashboard Traygle" />
        <script>
          {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://data.tray.com.br/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-W9BVK5');
          `}
        </script>
      </Helmet>

      <main role="main">
        <section className="grid-container">
          <div className="dashboard">
            <Navigation currentSection="campaign" />

            <div>
              {auth.store === null || !auth.store.google?.campaignId ? (
                <CampaingCreationCallToAction isInHome={false} />
              ) : (
                <div style={{ background: "white", borderRadius: "8px", boxShadow: "0px 12px 96px 0px #38414A08", padding: "40px 20px" }}>
                  <CampaignsListingSection />
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Campaign;
