import { MdKeyboardArrowRight } from "react-icons/md";
import styles from "./styles.module.scss";

const Ballons = ({ isInHome }) => {
  const ballons = [
    { 
      title:"Você não está sozinho nessa jornada!", 
      text:"Confira como a YoSports alcançou o sucesso ao integrar seus produtos ao Google Ads.",
      link:"https://www.tray.com.br/yosport/?utm_source=site&utm_medium=dobra-google&utm_campaign=case-yosport&utm_term=dobra-exclusiva-yosport",
    },
    { 
      title:"Experiência especial as novas contas Ads!", 
      text:"Gaste R$ 1.200,00 em até 60 dias e receba R$ 1.200,00 de volta na conta do Google Ads.",
      link:"https://www.google.com/intl/pt-br_br/ads/coupons/terms/",
    },
  ];

  return (
    <div className={ styles.container }>
      
      { ballons.map((ballon) => (
        <a 
          className={ `${styles.ballon} ${isInHome ? styles.is_in_home : ""}` } 
          key={ ballon.title }
          href={ ballon.link }
          target="_blank"
          rel="noreferrer"
        >

          <div className={ styles.text }>
            <strong>
              { ballon.title }
            </strong>
            <p>
              { ballon.text }
            </p>
          </div>
          
          <MdKeyboardArrowRight 
            color="#6B7280"
            size={ 30 }
          />
        </a>
      ))}

    </div>
  )
}

export default Ballons;