import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Button } from "../Button";
import { useAuth } from "../../hooks/useAuth";
import { useWizard } from "../../hooks/useWizard";
import { wizardSteps } from "../../contexts/WizardContext"
import { api } from "../../services/api";

import TipsImg from "../../assets/img/icons/tips.svg";
import MerchantImg from "../../assets/img/content/merchant.png";
import SpinnerBlueSvgGif from "../../assets/img/icons/spinner-blue.svg";

import { OverrideSiteVerification } from "./OverrideSiteVerification";
import { OverrideSiteClaiming } from "./OverrideSiteClaiming";
import { Tooltip } from "../Tooltip";
import { DisconnectAccountsButton } from "../DisconnectAccountsButton";

import styles from "./hasAccount.module.scss";

export function HasAccount({ accounts, changeAccounts }) {
  const auth = useAuth();
  const wizard = useWizard();

  const [connectingAccount, setConnectingAccount] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);

  const [showOverrideSiteClaiming, setShowOverrideSiteClaiming] = useState(false);

  const [currentVerifiedWebSite, setCurrentVerifiedWebSite] = useState('');
  const [showOverrideSiteVerification, setShowOverrideSiteVerification] = useState(false);

  useEffect(() => {
    // Check if we have any account with the website already
    // claimed. If so, we select it.
    const account = accounts.find(a => a.websiteClaimed)
    if (account) {
      auth.changeMerchantAccount(account);
    }
  }, []);

  async function handleLinkAccount() {
    try {
      setConnectingAccount(true);

      await api.post(
        `google/merchant_center/accounts/${auth.merchantAccount?.id}/link`
      );

      await api.post(
        `google/merchant_center/accounts/${auth.merchantAccount?.id}/verify_website`,
        {
          overwrite: false,
        }
      );

      await api.post(
        `google/merchant_center/accounts/${auth.merchantAccount?.id}/claim_website`,
        {
          overwrite: false,
        }
      );

      wizard.updateCurrentStep(wizardSteps.PHONE_VERIFICATION);
    } catch (err) {
      if (err?.response?.data?.error?.code === "4204") {
        setShowOverrideSiteClaiming(true);
      }

      if (err?.response?.data?.error?.code === "4205") {
        setCurrentVerifiedWebSite(err?.response?.data?.error?.websiteUrl);
        setShowOverrideSiteVerification(true);
      }

      if (err?.response?.data?.error?.code === "4209") {
        toast.error('Não é possível reivindicar o site para a conta selecionada. A sua URL está reivindicada por outra conta sob a qual o nosso sistema não possui permissões para editar. Por favor, escolha outra conta do Merchant Center ou crie uma nova.');
      }

      if (err?.response?.data?.error?.code === "4210") {
        toast.error('Não é possível reivindicar o site porque a sua URL ainda não foi verificada.');
      }

      if (err?.response?.data?.error?.code === "4211") {
        toast.error('Não foi possível verificar o site da sua loja. Acesse a sua conta do Merchant Center para realizar a verificação.');
      }

      if (err?.response?.data?.error?.code === "4212") {
        toast.error('Seu usuário Google não tem as autorizações necessárias para conectar esta conta do Merchant Center. Por favor, escolha outra conta do Merchant Center ou crie uma nova.');
      }
    } finally {
      setConnectingAccount(false);
    }
  }

  async function handleOverrideSiteVerification(merchantId = null) {
    merchantId = !merchantId ? auth.merchantAccount?.id : merchantId;

    try {
      setShowOverrideSiteVerification(false);
      setConnectingAccount(true);

      await api.post(
        `google/merchant_center/accounts/${merchantId}/verify_website`,
        {
          overwrite: true,
        }
      );

      await api.post(
        `google/merchant_center/accounts/${merchantId}/claim_website`,
        {
          overwrite: false,
        }
      );

      wizard.updateCurrentStep(wizardSteps.PHONE_VERIFICATION);
    } catch (err) {
      if (err?.response?.data?.error?.code === "4204") {
        setShowOverrideSiteClaiming(true);
      }

      if (err?.response?.data?.error?.code === "4205") {
        setCurrentVerifiedWebSite(err?.response?.data?.error?.websiteUrl);
        setShowOverrideSiteVerification(true);
      }

      if (err?.response?.data?.error?.code === "4210") {
        toast.error('Não é possível reivindicar o site porque a sua URL ainda não foi verificada.');
      }

      if (err?.response?.data?.error?.code === "4211") {
        toast.error('Não foi possível verificar o site da sua loja. Acesse a sua conta do Merchant Center para realizar a verificação.');
      }
    } finally {
      setConnectingAccount(false);
    }
  }

  async function handleOverrideSiteClaiming() {
    try {
      setShowOverrideSiteClaiming(false);
      setConnectingAccount(true);

      await api.post(
        `google/merchant_center/accounts/${auth.merchantAccount?.id}/claim_website`,
        {
          overwrite: true,
        }
      );

      wizard.updateCurrentStep(wizardSteps.PHONE_VERIFICATION);
    } catch (err) {
      if (err?.response?.data?.error?.code === "4204") {
        setShowOverrideSiteClaiming(true);
      }

      if (err?.response?.data?.error?.code === "4209") {
        toast.error('Não é possível reivindicar o site para a conta Merchant Center selecionada. O site está reivindicado por outra conta sob a qual o nosso sistema não possui permissões para editar. Por favor, escolha outra conta do Merchant Center ou crie uma nova.');
      }

    } finally {
      setConnectingAccount(false);
    }
  }

  async function handleCreateAccount() {
    // event.preventDefault();

    if (creatingAccount) return;

    try {
      setCreatingAccount(true);
      const response = await api.post("google/merchant_center/accounts");
      
      const newAccounts = [
        ...accounts,
        {
          id: response.data.id,
          name: response.data.name,
        },
      ];
      changeAccounts(newAccounts);
      auth.changeMerchantAccount({
        id: response.data?.id,
        name: response.data?.name
      });

      handleOverrideSiteVerification(response.data?.id);
    } catch (err) {
      if (err?.response?.data?.error?.code === "4208") {
        toast.error('O email da sua conta Google está associado a uma conta mestre do Merchant Center e não pode ser utilizado.');
      }
      if (err?.response?.data?.error?.code === "4213") {
        toast.error('Uma conta do Merchant Center já foi criada anteriormente. Selecione uma das contas já existentes para continuar a configuração.');
      }
    } finally {
      auth.setIsNewMerchantAccountCreated(true);
      setCreatingAccount(false);
    }
  }

  return (
    <>
      {!connectingAccount ? (
        <div className="widget">
          <h1 className="widget__title widget__title--google">
            Conectar uma conta Merchant Center.
          </h1>

          <div className="widget__text--google">
            A sua conta Google foi configurada com sucesso. <br />
            Agora vamos conectar a sua conta Google Merchant Center. <br />
            Ela permite que os seus produtos apareçam em todos os canais do Google.
          </div>

          <div className="widget__merchant--account-select" style={{ marginBottom:"20px" }}>
            <div className="widget__connect widget__connect--success">
              <div className="widget__connect__img">
                <img src={MerchantImg} alt="Merchant" />
              </div>
              <div className="widget__connect__user">
                <p>Selecionar Conta</p>
                <p>
                  Merchant Center{" "}
                  <Tooltip id="mc_account" message="Conta que será utilizada para enviar o feed de produtos">
                    <img src={TipsImg} alt="Dicas" />
                  </Tooltip>
                </p>
              </div>
            </div>

            <div>
              <select
                name="merchant_acccount"
                value={auth.merchantAccount?.id}
                onChange={(event) => {
                  const account = accounts.find(a => a.id === event.target.value)
                  auth.changeMerchantAccount(account)
                }}
                required
              >
                <option value="">Selecione uma conta</option>
                {accounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name} - {account.id}
                  </option>
                ))}
              </select>

              <div className={ styles.new_account_button }>
                <button onClick={ handleCreateAccount }>
                  Criar nova conta
                </button>
              </div>
            </div>
            
          </div>

          <div className="widget__connect__buttons widget__connect__buttons--connect text-center">
            {!creatingAccount && <DisconnectAccountsButton />}

            <Button
              disabled={!auth.merchantAccount?.id}
              onClick={handleLinkAccount}
              isSubmitting={creatingAccount}
              submitingMessage="Criando conta..."
            >
              Conectar Merchant Center
            </Button>
          </div>

          <OverrideSiteVerification
            currentVerifiedWebSite={currentVerifiedWebSite}
            setShowOverrideSiteVerification={setShowOverrideSiteVerification}
            showOverrideSiteVerification={showOverrideSiteVerification}
            handleOverrideSiteVerification={handleOverrideSiteVerification}
          />

          <OverrideSiteClaiming
            setShowOverrideSiteClaiming={setShowOverrideSiteClaiming}
            showOverrideSiteClaiming={showOverrideSiteClaiming}
            handleOverrideSiteClaiming={handleOverrideSiteClaiming}
          />
        </div>
      ) : (
        <div className="widget" style={{ padding: "3.75rem 5rem" }}>
          <h1 className="widget__title widget__title--google">
            Conectando uma conta Merchant Center.
          </h1>

          <div className="widget__text--google">
            A sua conta Google foi configurada com sucesso. <br />
            Agora vamos conectar a sua conta Google Merchant Center. <br />
            Ela permite que os seus produtos apareçam em todos os canais do Google.
          </div>

          <div
            className="widget__connect widget__connect--success"
            style={{
              justifyContent: "center",
            }}
          >
            <div className="widget__connect__img">
              <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
            </div>
            <div className="widget__connect__user">
              <p>Conta Merchant Center:</p>
              <p>{auth.merchantAccount?.name} - {auth.merchantAccount?.id}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
