import { Portal } from "react-portal";
import ReactTooltip from "react-tooltip";

/**
 * place  - top (default) | right | bottom | left
 *
 * type   - dark (default) | success | warning | error | info | light
 *
 * effect - float | solid (default)
 */
export function Tooltip({
  id,
  message,
  place = "top",
  type = "dark",
  effect = "solid",
  children,
  ...rest
}) {
  return (
    <>
      <span data-tip data-for={id} {...rest}>
        {children}
      </span>
      {/* Send the tooltip component to #tooltipsContainer */}
      <Portal node={document && document.getElementById("tooltipsContainer")}>
        <ReactTooltip id={id} place={place} type={type} effect={effect} style={{ maxWidth:"500px" }}>
          {message}
        </ReactTooltip>
      </Portal>
    </>
  );
}
