import { Link, useHistory } from "react-router-dom";
import { wizardSteps } from "../../contexts/WizardContext";

import { useAuth } from "../../hooks/useAuth";
import { useWizard } from "../../hooks/useWizard";

import { Button } from "../Button";
import { Sidebar } from "../Sidebar";

export function CreateCampaignLater({ isOpen, setIsOpen }) {
    const auth = useAuth();
    const history = useHistory();
    const wizard = useWizard();

    function handleOpen() {
        setIsOpen(true);
        document.querySelector("html").classList.add("is-reveal-open");
    }

    function handleClose(event, redirect = false) {
        if (event) {
            event.preventDefault();
        }

        setIsOpen(false);
        document.querySelector("html").classList.remove("is-reveal-open");

        if (redirect) {
            wizard.updateCurrentStep(wizardSteps.COMPLETED);
            history.push({ pathname: "/", state: { showWelcomeModal: true } })
        }
    }

    return (
        <Sidebar
            isSidebarOpen={isOpen}
            onClose={handleClose}
            onOpen={handleOpen}
        >
            <section
                className="modal modal-campaign full reveal"
                id="modal-campaign"
                data-reveal
                style={{
                    display: "block",
                }}
            >
                <div className="modal-campaign__right">
                    <h2>
                        Você tem certeza de que não quer criar a {auth.campaignTypeName} agora?
                    </h2>
                    <p>
                        A {auth.campaignTypeName} é uma forma de mostrar seus produtos para
                        potenciais clientes por toda rede Google: <br />
                        YouTube, Gmail e muito mais.
                    </p>
                    <Link onClick={(e) => handleClose(e)} to='/'>
                        Voltar e criar {auth.campaignTypeName}
                    </Link>

                    <Button
                        onClick={(e) => handleClose(e, true)}
                        data-close
                        aria-label="Close reveal"
                    >
                        Sim, quero fazer depois
                    </Button>
                </div>
            </section>
        </Sidebar>
    );
}
