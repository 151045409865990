import { useEffect, useState } from "react";

import { Tooltip } from "../../components/Tooltip";
import { api } from "../../services/api";

import TipsImg from "../../assets/img/icons/tips.svg";
import SpinnerBlueSvgGif from "../../assets/img/icons/spinner-blue.svg";

function WorstSellingProducts({ lastDays }) {
    const [worstSelling, setWorstSelling] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api
            .get("/google/reports/products/worst_selling", {
                params: {
                    dateRange: lastDays,
                },
            })
            .then((result) => {
                setWorstSelling(result.data);
            })
            .catch((err) => { })
            .finally(() => setLoading(false))
    }, [lastDays]);

    return (
        <div className="cell small-12 large-3">
            <h4>
                Piores Performances{' '}
                <Tooltip id="worst_selling_tip" message="Produto que estão gerando visitas em sua loja, mas não estão gerando pedido. Dica: Verifique se o produto está com uma boa descrição e preço compatível com o mercado.">
                    <img src={TipsImg} alt="Dicas" />
                </Tooltip>
            </h4>

            {loading ? (
                <div className="text-align-center">
                    <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
                </div>
            ) : (
                worstSelling.length === 0 ? (
                    <div className="text-align-center">
                        <p>Não há nenhum produto para exibir.</p>
                    </div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Produtos</th>
                                <th>Cliques</th>
                                <th>Vendas</th>
                            </tr>
                        </thead>

                        <tbody>
                            {worstSelling.map((item) => (
                                <tr key={item.id}>
                                    <td className="product">
                                        <img
                                            className="mw-40px"
                                            src={item.imageUrl}
                                            alt={item.name}
                                        />

                                        <div>
                                            <a href={item.url} target="_blank">{item.name}</a>

                                            {item.stock &&
                                                <div>
                                                    <p className="stock">Estoque: {item.stock}</p>
                                                </div>
                                            }
                                        </div>
                                    </td>

                                    <td>{item.clicks}</td>
                                    <td>{item.conversions}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            )}
        </div>
    );
}

export default WorstSellingProducts;
