import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { toast } from "react-toastify";

import { HasAccount } from "../../../components/GoogleContaConectadaComponents/HasAccount";
import { NoAccount } from "../../../components/GoogleContaConectadaComponents/NoAccount";
import { ProgressIndicator } from "../../../components/Wizard/ProgressIndicator";
import { useAuth } from "../../../hooks/useAuth";
import { api } from "../../../services/api";

import SpinnerBlueSvgGif from "../../../assets/img/icons/spinner-blue.svg";

export default function GoogleContaConectadaPage() {
  const auth = useAuth();
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    toast.success(`A conta Google "${auth.primaryEmail}" foi conectada com sucesso.`);
  }, [auth.primaryEmail]);

  useEffect(() => {
    async function loadAccounts() {
      if (!auth.primaryEmail) return;

      const response = await api.get("google/merchant_center/accounts");
      setLoading(false);
      setAccounts(response?.data?.accounts);
      auth.setAllowMerchantCenterAccountCreation(response?.data?.allowMerchantCenterAccountCreation)

      // There might be a delay between the creation of a new MC account and its 
      // availability on the GET google/merchant_center/accounts endepoint. So
      // if the response is empty, we check if we have a local MC account. If so,
      // this account is already created and is valid.
      if (response.data?.accounts?.length === 0 && !!auth.merchantAccount?.id) {
        setAccounts([auth.merchantAccount]);
      }

    }
    loadAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.primaryEmail, auth.merchantAccount?.id]);

  return (
    <>
      <Helmet>
        <title>Google - Traygle</title>
        <meta
          name="description"
          content="Associar a sua conta Google irá permitir a sincronização de seus produtos com o Merchant Center, criar anúncios com Google Ads e também a criação de campanhas."
        />
        <meta property="og:title" content="Google  - Traygle" />
        <meta
          property="og:description"
          content="Associar a sua conta Google irá permitir a sincronização de seus produtos com o Merchant Center, criar anúncios com Google Ads e também a criação de campanhas."
        />
        <script>
          {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://data.tray.com.br/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-W9BVK5');
          `}
        </script>
      </Helmet>

      <div className="account-connected">
        <ProgressIndicator currentStep={2} />

        {loading ? (
          <div className="widget" style={{ padding: "3.75rem 5rem" }}>
            <h1 className="widget__title widget__title--google">
              Conectar uma conta Merchant Center.
            </h1>

            <div className="widget__text--google">
              A sua conta Google foi configurada com sucesso. <br />
              Agora vamos conectar a sua conta Google Merchant Center. <br />
              Ela permite que os seus produtos apareçam em todos os canais do Google.
            </div>

            <div
              className="widget__connect widget__connect--success"
              style={{
                justifyContent: "center",
              }}
            >
              <div className="widget__connect__img">
                <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
              </div>
            </div>
          </div>
        ) : (
          accounts.length > 0 ? (
            <HasAccount accounts={accounts} changeAccounts={setAccounts} />
          ) : (
            <NoAccount />
          )
        )}
      </div>
    </>
  );
}
