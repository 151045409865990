import { useEffect, useState } from "react";
import cx from "classnames";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
import { AiOutlineAreaChart } from "react-icons/ai";

import { formatRoas, formatRoasPercentage } from "../../utils/format";

import TipsImg from "../../assets/img/icons/tips.svg";
import { Tooltip } from "../Tooltip";

function RoasCard({ roas }) {
    const [color, setColor] = useState('#1ABC9C');
    const [icon, setIcon] = useState(<ImArrowUp size={12} color={color} style={{ marginRight: 5 }} />);
    const [barWidth, setBarWidth] = useState('100%')

    useEffect(() => {
        if (roas > 0 && roas < 1) {
            setColor('#F1556C');
            setIcon(<ImArrowDown size={12} color={'#F1556C'} style={{ marginRight: 5 }} />);
        }

        setBarWidth(formatRoasPercentage(roas).replace('-', '').replace(',', '.'));
    }, [roas]);

    return (
        <section
            className={cx("dashboard--states-dash-payed--aside-item", {
                "icon-green": true,
                "icon-yellow": false,
                "icon-red": false,
            })}
        >
            <div className="dashboard--states-dash-payed--aside-item-header">
                <span className="fs-0" style={{ backgroundColor: color }}>
                    <AiOutlineAreaChart size={18} />
                </span>

                <p>{formatRoas(roas)} vezes</p>
            </div>

            <aside className="dashboard--states-dash-payed--aside-item-aside">
                ROAS
                <Tooltip id="roas_tip" message="Retorno do investimento em publicidade">
                    <img src={TipsImg} alt="Dicas" />
                </Tooltip>
            </aside>

            <footer className="dashboard--states-dash-payed--aside-item-footer">
                <p></p>
                <p>
                    <span />

                    {roas !== 0 &&
                        <span>{icon}{formatRoasPercentage(roas)}</span>
                    }
                </p>

                <div>
                    <span style={{ backgroundColor: color, width: barWidth }}></span>
                </div>
            </footer>
        </section>
    );
}

export default RoasCard;
