import ModalContainer from "react-modal";

const customStyles = (maxWidth = "590px", contentBg = "white", contentWidth = "100%", minHeight = "") => ({
  overlay: {
    backgroundColor: "rgba(5, 2, 6, 0.8)",
    zIndex: 99999,
  },
  content: {
    overflow: "auto",
    maxWidth,
    minHeight,
    backgroundColor: contentBg, // Add this line to set the background color of the modal content
    width: contentWidth,
    borderRadius: "8px",
    border: "unset",
    padding: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    maxHeight: "100%",
    overflow: "auto",
  },
});

ModalContainer.setAppElement("#traygle_app");

export function Modal({ isOpen, children, closeModal, maxWidth, contentBg, contentWidth, minHeight, ...rest }) {
  return (
    <ModalContainer isOpen={isOpen} onRequestClose={closeModal} style={customStyles(maxWidth, contentBg, contentWidth, minHeight)} {...rest}>
      {children}
    </ModalContainer>
  );
}
