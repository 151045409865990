import { useEffect, useState } from "react";
import { api } from "../../services/api";
import { Pagination } from "../../components/Pagination";
import { ProductProblemItem } from "../../components/ProductProblemItem";
import { useAuth } from "../../hooks/useAuth";
import SpinnerBlueSvgGif from "../../assets/img/icons/spinner-blue.svg";
import { AiOutlineSearch } from "react-icons/ai";

function ProductProblems() {
  const auth = useAuth();

  const [productProblems, setProductProblems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isSearchRequest, setIsSearchRequest] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);

  const [pageTokens, setPageTokens] = useState([null, ""]);

  useEffect(() => {
    loadData();
  }, [perPage, currentPage]);

  async function loadData(isNewSearch) {
    try {
      setIsSearchRequest(false);
      setLoading(true);

      let url = `/google/merchant_center/accounts/${auth.merchantAccount?.id}/products_status`;

      let params = {
        limit: 10,
        page: currentPage,
        pageToken: pageTokens[currentPage],
      };

      if (searchTerm && searchTerm !== "") {
        params["query"] = searchTerm;
        setIsSearchRequest(true);
      }

      if (isNewSearch) {
        params["page"] = 1;
        setCurrentPage(1);
      }

      const response = await api.get(url, { params });

      setProductProblems(response.data.results);
      setLastPage(response.data?.lastPage);
      setTotalItems(response.data?.totalItems);

      if (response.data?.nextPageToken) {
        let tokens = JSON.parse(JSON.stringify(pageTokens));
        tokens[parseInt(response.data.currentPage) + 1] = response.data?.nextPageToken;
        setPageTokens(tokens);
      }
    } catch {
      setProductProblems([]);
      setPageTokens([null, ""]);
      setLastPage(1);
      setTotalItems(0);
    } finally {
      setLoading(false);
    }
  }

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function checkIfHasNextPage() {
    if (isSearchRequest) {
      setHasNextPage(productProblems?.length >= perPage);
      return;
    }

    setHasNextPage(currentPage < lastPage);
  }

  useEffect(() => {
    checkIfHasNextPage();
  }, [productProblems]);

  return (
    <section class="reports__products">
      <div className="header">
        <section>
          <div className="input-control">
            <input type="text" placeholder="Busque pelo título do produto" value={searchTerm} onChange={(event) => setSearchTerm(event.target.value)} />

            <button onClick={() => loadData(true)}>
              <AiOutlineSearch className="mr-0.5" />
              Buscar
            </button>
          </div>
        </section>
      </div>

      <main>
        {loading ? (
          <div className="text-align-center">
            <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
          </div>
        ) : productProblems.length === 0 ? (
          <div className="text-align-center">
            <p>Não há nenhum problema de produto.</p>
          </div>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th style={{ minWidth: 215 }}>Descrição do problema</th>
                  <th>Solução</th>
                  <th>Status</th>
                  <th className="text-align-center">Ações</th>
                </tr>
              </thead>

              <tbody>
                {productProblems.map((productProblem) => (
                  <ProductProblemItem key={productProblem.id} productProblem={productProblem} />
                ))}
              </tbody>
            </table>

            {totalItems === 0 && (
              <div className="text-align-center">
                <p>Não existem produtos a serem exibidos.</p>
              </div>
            )}
          </>
        )}
        <section className="pagination">
          <div className="select-container">
            <p>Exibir</p>
            <select
              name="limit"
              value={perPage}
              onChange={(event) => {
                setCurrentPage(1);
                setPerPage(Number(event.target.value));
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
            </select>

            {!isSearchRequest && (
              <p className="total-itens">
                {currentPage * perPage - perPage + 1} a {currentPage * perPage > totalItems ? totalItems : currentPage * perPage} de {totalItems}
              </p>
            )}
          </div>

          <Pagination hasNextPage={hasNextPage} currentPage={currentPage} lastPage={lastPage} totalItems={totalItems} onPageChange={handlePageChange} showFirstAndLastPageArrows={false} />
        </section>
      </main>
    </section>
  );
}

export default ProductProblems;
