import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";

import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

const Control = ({ currentOpen, itemsQuantity, setCurrentOpen }) => {
  const spheres = Array.from({ length:itemsQuantity }, (_, i) => i + 1);
  const hasNext = currentOpen < itemsQuantity - 1;

  const hasPrev = currentOpen > 0;

  return (
    <div className={ styles.container }>
      
      <button 
        onClick={() => setCurrentOpen(currentOpen - 1) } 
        style={hasPrev ? {} : { pointerEvents:"none", opacity:0 }}
      >
        <MdKeyboardArrowLeft 
          color="#6B7280"
          size={ 22 }
        />
        Voltar
      </button>

      <div className={ styles.steps }>
        { spheres.map((_, i) => (
          <button 
            className={ currentOpen === i ? styles.active : "" } 
            onClick={() => setCurrentOpen(i)}
            key={ i }
          />
        )) }
      </div>
          
      {hasNext ? 
        (<button onClick={ () => setCurrentOpen(currentOpen + 1) } style={{ width:"142px", display:"flex", alignItems:"center", justifyContent:"flex-end" }}>
          Avançar
          <MdKeyboardArrowRight 
            color="#6B7280"
            size={ 22 }
          />
        </button>)
      :
      (
        <Link  
          className={ styles.button }
          to="/pmax"
        >
          Criar Campanha
        </Link>
      )
      }
      
    </div>
  )
}

export default Control;