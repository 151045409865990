import { Modal } from "../Modal";
import GoogleSignInButton from "../GoogleSignInButton";
import GoogleAuthScopesImg from "../../assets/img/content/google-auth-scopes.gif";
import { Button } from "../Button";

import "./styles.scss"
import { useEffect, useState } from "react";

export function ScopesInfoModal({ isOpen, closeModal, onGoogleSignInButtonClick, hidden }) {

    const [openButtonByItSelf, setOpenButtonByItSelf] = useState(false);

    useEffect(() => {
        if(hidden && isOpen) {
            onGoogleSignInButtonClick()
            setOpenButtonByItSelf(true);
        }
    }, [isOpen]);

    useEffect(() => {
        if(openButtonByItSelf) closeModal();
    }, [ openButtonByItSelf ]);

    if(hidden) return <></>;

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} backgroundColor="red">
            <div className="auth-modal-title">
                <p>Muita atenção para continuar!</p>
                <p className="close" onClick={closeModal}>x</p>
            </div>
            <div className="auth-modal-body">
                <p>Atenção, na próxima tela <b>é necessário marcar todas as caixas de permissão.</b><br />Caso alguma caixa não seja marcada, <b>não será possível continuar a integração.</b></p>
                <div className="text-center">
                    <img src={GoogleAuthScopesImg} alt="auth-scopes" className="auth-scopes" />
                </div>
            </div>
            <div className="auth-modal-footer">
                <div className="widget__connect__buttons">
                    <Button
                        className='newGoogleAccButton'
                        onClick={() => window.open('https://accounts.google.com/')}
                    >
                        Não tenho email no Google
                    </Button>
                    <GoogleSignInButton 
                        onClick={onGoogleSignInButtonClick} 
                    />
                </div>
            </div>
        </Modal>
    )
}