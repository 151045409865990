import { useState } from "react";
import { FaEye, FaList } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import { BiLinkExternal } from "react-icons/bi";

import { Tooltip } from "../Tooltip";
import { Sidebar } from "../Sidebar";
import LogsModal from "../LogsModal";
import { formatMoney, formatRoas } from "../../utils/format";

import styles from "./styles.module.scss";
import ProductJsonModal from "../ProductJsonModal";

export function ReportItem({ report, reportType, freeListingsImage }) {
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const [isProductJsonModalOpen, setIsProductJsonModalOpen] = useState(false);

  return (
    <>
      <tr>
        <td className="product">
          <img className={styles.productImage} src={report.imageUrl} alt="pic" />

          <div>
            <p className="product-name">
              {report.name}
              <a href={report.url} target="_blank">
                <BiLinkExternal />
              </a>
            </p>

            <div>
              <p>
                Ref.: {report?.reference || "-"} / Marca: {report?.brand || "-"}
              </p>
              <p>
                {report?.stock || 0} un. / a partir de{" "}
                {formatMoney(Number(report.price))}
              </p>
              {report.variantsNumber > 0 &&
                <p className="variations">{report.variantsNumber} variações</p>
              }
            </div>
          </div>
        </td>

        <td>{report.impressions}</td>
        <td>{report.clicks}</td>

        {reportType === 'ads' &&
          <>
            <td>{report.conversions}</td>
            <td>{report?.cost ? formatMoney(Number(report?.cost)) : "-"}</td>
            <td>
              {report?.conversionsValue
                ? formatMoney(Number(report?.conversionsValue))
                : "-"}
            </td>
            <td>{formatRoas(report?.roas) || "-"}</td>
          </>
        }

        <td width="100px">
          {report.status === "" && (
            <span className="status in-analise">Em Analise</span>
          )}
          {report.status === "approved" && (
            <span className="status approved">Aprovado</span>
          )}
          {report.status === "disapproved" && (
            <span className="status disapproved">Reprovado</span>
          )}
        </td>
        <td className="open-fast-edit">
          <button onClick={() => setIsProductJsonModalOpen(true)}>
            <i><FaEye /></i>
          </button>
          <button onClick={() => setIsLogsModalOpen(true)}>
            <i><FaList /></i>
          </button>
        </td>
        {/* <td className="open-fast-edit">
        <Sidebar
          icon={
            <button>
              <Tooltip id="cliques" message="Editar produto">
                <FiEdit color="#d1d3d5" size={20} />
              </Tooltip>
            </button>
          }
          className={styles.sidebarEditItemContainer}
          sidebarClass={styles.sidebar}
        >
          {(props) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.handleCloseSidebar(handleUpdateItem);
              }}
            >
              <main>
                <section className={styles.headerContentContainer}>
                  <h2>Edição rápida</h2>
                  <button type="button" onClick={props.handleCloseSidebar}>
                    <GrFormClose color="#343A40" size={24} />
                  </button>
                </section>

                <section className={styles.contentContainer}>
                  <div className={`${styles.formControl} ${styles.flex}`}>
                    <div className={`${styles.switch} ${styles.round}`}>
                      <input id="activeProduct" type="checkbox" />
                      <label htmlFor="activeProduct"></label>
                    </div>
                    <label htmlFor="activeProduct">Produto ativo</label>
                  </div>

                  <div className={`${styles.formControl}`}>
                    <label htmlFor="productName">Nome do produto</label>
                    <input
                      type="text"
                      id="productName"
                      name="productName"
                      defaultValue=""
                      placeholder="Nome completo para exibir do produto será inserido aqui"
                    />
                  </div>

                  <div className={`${styles.formControl}`}>
                    <label htmlFor="productCategory">Categoria</label>
                    <input
                      type="text"
                      id="productCategory"
                      name="productCategory"
                      defaultValue=""
                      placeholder="Nome completo para exibir do produto será inserido aqui"
                    />
                  </div>

                  <section className={`grid-x ${styles.withThreeItens}`}>
                    <div
                      className={`${styles.formControl} cell small-12 large-4`}
                    >
                      <label htmlFor="productSellingPrice">
                        Preço de venda
                      </label>
                      <input
                        type="text"
                        id="productSellingPrice"
                        name="productSellingPrice"
                        defaultValue=""
                        placeholder="7,00"
                      />
                    </div>

                    <div
                      className={`${styles.formControl} cell small-12 large-4`}
                    >
                      <label htmlFor="productCostPrice">Preço de custo</label>
                      <input
                        type="text"
                        id="productCostPrice"
                        name="productCostPrice"
                        defaultValue=""
                        placeholder="1,50"
                      />
                    </div>

                    <div
                      className={`${styles.formControl} cell small-12 large-4`}
                    >
                      <label htmlFor="productStock">Estoque</label>
                      <input
                        type="text"
                        id="productStock"
                        name="productStock"
                        defaultValue=""
                        placeholder="Nome completo para exibir do produto será inserido aqui"
                      />
                    </div>
                  </section>

                  <div className={`${styles.formControl} ${styles.flex}`}>
                    <div className={`${styles.switch} ${styles.round}`}>
                      <input id="isInPromotionProduct" type="checkbox" />
                      <label htmlFor="isInPromotionProduct"></label>
                    </div>
                    <label htmlFor="isInPromotionProduct">
                      Produto em promoção
                    </label>
                  </div>

                  <section className={`grid-x ${styles.withTwoItens}`}>
                    <div
                      className={`${styles.formControl} cell small-12 large-4`}
                    >
                      <label htmlFor="productPromotionPrice">
                        Preço da promoção
                      </label>
                      <input
                        type="text"
                        id="productPromotionPrice"
                        name="productPromotionPrice"
                        defaultValue=""
                        placeholder="7,00"
                      />
                    </div>

                    <div
                      className={`${styles.formControl} cell small-12 large-4`}
                    >
                      <label htmlFor="productPromotionTime">
                        Período da promoção
                      </label>
                      <input
                        type="text"
                        id="productPromotionTime"
                        name="productPromotionTime"
                        defaultValue=""
                        placeholder="24/08/2020 até 27/08/2020"
                      />
                    </div>
                  </section>
                </section>
              </main>

              <footer className={styles.buttonsContentContainer}>
                <button onClick={props.handleCloseSidebar} type="reset">
                  Cancelar
                </button>
                <button type="submit">Salvar</button>
              </footer>
            </form>
          )}
        </Sidebar>
      </td> */}
      </tr>

      <LogsModal
        handleClose={() => setIsLogsModalOpen(!isLogsModalOpen)}
        isOpen={isLogsModalOpen}
        scope='product'
        scopeId={report.id}
        product={report}
      />

      <ProductJsonModal
        handleClose={() => setIsProductJsonModalOpen(!isProductJsonModalOpen)}
        isOpen={isProductJsonModalOpen}
        productId={report.id}
        reportType={reportType}
        freeListingsImage={freeListingsImage}
      />
    </>
  );
}
