import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import ReactInputMask from "react-input-mask";
import cx from "classnames";

import { Sidebar } from "../Sidebar";
import { useAuth } from "../../hooks/useAuth";
import { api } from "../../services/api";

import styles from "./styles.module.scss";
import { Button } from "../Button";

export function UpdateAddress({ address, setAddress }) {
  const auth = useAuth();

  const [addressStreet, setAddressStreet] = useState(address?.street);
  const [addressCep, setAddressCep] = useState(address?.postalCode);
  const [addressCity, setAddressCity] = useState(address?.city);
  const [addressState, setAddressState] = useState(address?.state);
  const [errors, setErrors] = useState({
    street: false,
    cep: false,
    city: false,
    state: false,
    other: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAddressStreet(address?.street);
    setAddressCep(address?.postalCode);
    setAddressCity(address?.city);
    setAddressState(address?.state);
  }, [address]);

  async function handleUpdateAddress() {

    if (!auth?.store || !auth?.store?.id) {
      throw Error("Invalid data.");
    }

    //addressCity.trim() === "" ||
    //addressState.trim() === ""

    if (
      addressStreet.trim() === "" ||
      addressCep.trim() === "" ||
      addressCity.trim() === "" ||
      addressState.trim() === ""
    ) {
      if (addressStreet.trim() === "") {
        setErrors((old) => {
          return {
            ...old,
            street: true,
          };
        });
      }

      if (addressCep.trim() === "" || addressCep.trim().length < 8) {
        setErrors((old) => {
          return {
            ...old,
            cep: true,
          };
        });
      }

      if (addressCity.trim() === "") {
        setErrors((old) => {
          return {
            ...old,
            city: true,
          };
        });
      }

      if (addressState.trim() === "") {
        setErrors((old) => {
          return {
            ...old,
            state: true,
          };
        });
      }

      throw Error("Invalid data.");
    }

    let data = {
      address: {
        street: addressStreet.trim(),
        city: addressCity.trim(),
        state: addressState.trim(),
        postalCode: addressCep.trim(),
      },
    };

    setLoading(true);
    api.put(`/platforms/tray/stores/${auth.store.id}`, data)
      .then(() => setAddress(data.address))
      .finally(() => setLoading(false))
  }

  return (
    <Sidebar
      className={styles.settingsAddressModal}
      sidebarClass={styles.sidebar}
      icon={<FiEdit color="#d1d3d5" size={16} />}
    >
      {(props) => (
        <form>
          <div
            className={cx(styles.formControler, {
              [`${styles.inputWithError}`]: errors.street,
            })}
          >
            <label htmlFor="address-street">Rua</label>
            <input
              type="text"
              id="address-street"
              value={addressStreet}
              onChange={(e) => {
                if (e.target.value.trim() !== "")
                  setErrors((old) => {
                    return {
                      ...old,
                      street: false,
                    };
                  });
                setAddressStreet(e.target.value);
              }}
              placeholder="Digite o nome da rua"
            />
          </div>

          <div
            className={cx(styles.formControler, {
              [`${styles.inputWithError}`]: errors.cep,
            })}
          >
            <label htmlFor="address-cep">CEP</label>
            <ReactInputMask
              id="address-cep"
              type="text"
              mask="99999-999"
              value={addressCep}
              onChange={(e) => {
                if (e.target.value.length === 8)
                  setErrors((old) => {
                    return {
                      ...old,
                      cep: false,
                    };
                  });
                setAddressCep(e.target.value);
              }}
              placeholder="Digite o seu CEP"
            />
          </div>

          <div
            className={cx(styles.formControler, {
              [`${styles.inputWithError}`]: errors.city,
            })}
          >
            <label htmlFor="address-city">Cidade</label>
            <input
              type="text"
              id="address-city"
              value={addressCity}
              onChange={(e) => {
                if (e.target.value.trim() !== "")
                  setErrors((old) => {
                    return {
                      ...old,
                      city: false,
                    };
                  });
                setAddressCity(e.target.value);
              }}
              placeholder="Digite o nome da cidade"
            />
          </div>

          <div
            className={cx(styles.formControler, {
              [`${styles.inputWithError}`]: errors.state,
            })}
          >
            <label htmlFor="address-state">Estado</label>
            <select
              id="estado"
              value={addressState}
              onChange={(e) => {
                if (e.target.value.trim() !== "")
                  setErrors((old) => {
                    return {
                      ...old,
                      state: false,
                    };
                  });
                setAddressState(e.target.value);
              }}
              name="estado"
            >
              <option value="">Selecione um Estado</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </select>
          </div>

          <Button
            disabled={false}
            onClick={(event) => {
              event.preventDefault();
              props.handleCloseSidebar(handleUpdateAddress);
            }}
            // isSubmitting={loading}
            submitingMessage="Atualizando..."
          >
            Atualizar
          </Button>
        </form>
      )}
    </Sidebar>
  );
}
