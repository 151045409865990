import { useState } from "react";

import { Button } from "../Button";
import { Sidebar } from "../Sidebar";
import { replaceHttp } from "../../utils/format";

import UrlIconSvg from "../../assets/img/icons/url-icon.svg";

import styles from "./hasAccount.module.scss";

export function OverrideSiteVerification({
  setShowOverrideSiteVerification,
  showOverrideSiteVerification,
  handleOverrideSiteVerification,
  currentVerifiedWebSite
}) {
  const [acceptedOverrideSiteVerification, setAcceptedOverrideSiteVerification] = useState(false);

  return (
    <Sidebar
      isSidebarOpen={showOverrideSiteVerification}
      onOpen={() => setShowOverrideSiteVerification(true)}
      onClose={() => setShowOverrideSiteVerification(false)}
      className={styles.verifyWebSiteContentContainer}
      sidebarClass={styles.sidebar}
    >
      <h4 className={styles.title}>
        Você já possui a URL{" "}
        <span>{replaceHttp(currentVerifiedWebSite)}</span> vinculada à sua
        conta.
      </h4>

      <img
        src={UrlIconSvg}
        alt="URL Icon"
        className={styles.verifyIcon}
      />

      <p className={styles.description}>
        A sua conta Google Merchant Center possui uma URL Verificada. Não
        tem problema! Ainda é possível utilizar esta conta para a
        integração Tray. <br />
        <br />
        Ao confirmar, você autoriza a Tray a modificar as configurações
        das suas contas Merchant Center.
      </p>

      <div className={styles.buttonsAndCheckbox}>
        <label className="control control--checkbox center-box">
          <input
            type="checkbox"
            id="confirm"
            name="confirm"
            className="confirmed"
            checked={acceptedOverrideSiteVerification}
            onChange={() => {
              setAcceptedOverrideSiteVerification((oldVersion) => !oldVersion);
            }}
          />
          <div className="control__indicator"></div>

          <span>
            Entendo que a minha configuração de conta Google Merchant
            Center será modificada pela integração da Tray.
          </span>
        </label>

        <Button
          className={styles.acceptConfiguration}
          disabled={!acceptedOverrideSiteVerification}
          onClick={handleOverrideSiteVerification}
        >
          Aceitar configuração e atualizar URL
        </Button>
        <Button
          className={styles.cancelConfiguration}
          onClick={() => {
            setShowOverrideSiteVerification(false);
          }}
        >
          Escolher outra conta Merchant Center
        </Button>
      </div>
    </Sidebar>
  );
}
