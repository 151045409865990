import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";

import { GrClose } from "react-icons/gr";
import "./styles.scss";

const ResetModal = ({ isOpen, closeModal, reset }) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="reset_google_ads_integration">

        <div className="reset_google_ads_integration-header">
          <p>Reiniciar Integração do Google Ads</p>
          <GrClose
            color="#6B7280"
            className="close-icon"
            onClick={closeModal}
          />
        </div>

        <div className="reset_google_ads_integration-texts">
          <h3>
            Deseja reiniciar a integração das contas Google?
          </h3>
          <p>
            Ao reiniciar, você voltará à primeira etapa, de login da conta Google, resetando todo o progresso anterior.
          </p>
        </div>

        <div className="reset_google_ads_integration-buttons">
          <Button className="reset_google_ads_integration-buttons-cancel" onClick={ closeModal }>
            Cancelar
          </Button>

          <Button className="reset_google_ads_integration-buttons-confirm" onClick={ reset }>
            Reiniciar
          </Button>
        </div>

      </div>
    </Modal>
  )
}

export default ResetModal