import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Modal } from "../Modal";
import { BsXLg } from "react-icons/bs";
import { formatMoney } from "../../utils/format";
import { api } from "../../services/api";
import { toast } from "react-toastify";
import SpinnerBlueSvgGif from "../../assets/img/icons/spinner-blue.svg";
import LogItem from "../LogItem";
import { notShowLog } from "../../constants/logs";

export default function LogsModal({ handleClose, isOpen, campaign, product, scope, scopeId }) {
    const auth = useAuth();

    const [logs, setLogs] = useState([]);
    const [hasMoreLogs, setHasMoreLogs] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Reset variables
        setLogs([]);
        setHasMoreLogs(true);
        setCurrentPage(1);

        getLogs(1);
    }, [isOpen]);

    const getLogs = async (page) => {
        try {
            // The max number of resources that is going to be fetched from the API
            const LIMIT = 20;

            setLoading(true);

            // Set and call the API url to get the options accordingly to the filter type
            let url = `/platforms/tray/stores/${auth.store.id}/logs?limit=${LIMIT}&page=${page}&scope=${scope}`
            if (scopeId) url += `&scopeId=${scopeId}`

            const response = await api.get(url);

            // Get the fetched logs
            let fetchedLogs = response.data || [];

            // Set the logs or aggregate them to existing ones based on the
            // requested page
            if (page === 1) {
                setLogs(fetchedLogs)
            } else {
                setLogs([...logs, ...fetchedLogs]);
            }

            // In case we have already fetched all the logs, set the setHasMoreLogs 
            // variable to false
            if (fetchedLogs.length === 0 || fetchedLogs.length < LIMIT) {
                setHasMoreLogs(false);
                return;
            }
 
            // Set the next page that is going to be requested
            setCurrentPage(page + 1);
        } catch (e) {
            console.log(e)
            // const canShowLog = !notShowLog.itWasntPossibleToLoadTheLogs.includes(auth.store.id);
            // if(canShowLog) toast.error(`Não foi possível carregar os logs. Por favor, tente novamente mais tarde.`);
        } finally {
            setLoading(false); 
        }
    }

    return (
        <Modal closeModal={handleClose} isOpen={isOpen} maxWidth={1000}>
            <section class="logs__modal">
                <main class="grid-x">
                    <section class="cell small-12 logs__modal__header">
                        <h1>Registro de alterações</h1>
                        <button onClick={handleClose}><i><BsXLg /></i></button>
                    </section>

                    <section class="cell small-12 logs__modal__body">
                        {scope === 'campaign' && campaign &&
                            <div class="campaign">
                                <div>
                                    <p class="campaign-name">
                                        {campaign.name}
                                    </p>
                                    {/* <div>
                                        <p>340 produtos</p>
                                    </div> */}
                                </div>

                                <span class={`status ${campaign.status === 'ENABLED' ? 'enabled' : 'disabled'}`}>
                                    {campaign.status === 'ENABLED' ? 'Ativo' : 'Inativo'}
                                </span>
                            </div>
                        }

                        {scope === 'product' && product &&
                            <div class="product">
                                <img src={product.imageUrl} alt="pic" />

                                <div>
                                    <p className="product-name">
                                        {product.name}
                                    </p>

                                    <div>
                                        <p>
                                            Ref.: {product?.reference || "-"} / Marca: {product?.brand || "-"}
                                        </p>
                                        <p>
                                            {product?.stock || 0} un. / a partir de{" "}
                                            {formatMoney(Number(product.price))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }

                        {(hasMoreLogs || logs.length > 0) &&
                            <table>
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Usuário</th>
                                        <th>Alteração realizada</th>
                                        <th>{scope === 'store' ? 'Mensagem' : 'Dados'}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {logs.map(l => <LogItem key={l.id} log={l} />)}
                                </tbody>
                            </table>
                        }

                        {!loading && logs.length === 0 &&
                            <div className="text-align-center" style={{ display: 'block' }}>
                                <p>Ainda não existe o registo de nenhuma alteração realizada.</p>
                            </div>
                        }

                        {loading ? (
                            <div className="text-align-center" style={{ display: 'block' }}>
                                <img src={SpinnerBlueSvgGif} alt="Animation spinner" />
                            </div>
                        ) : (
                            hasMoreLogs && (
                                <div class="cell small-12 show-more">
                                    <button onClick={() => getLogs(currentPage)}>Mostrar mais</button>
                                </div>
                            )
                        )}
                    </section>
                </main>
            </section>
        </Modal>
    );
}
