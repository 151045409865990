const mapTrayCategory = (category, parentName = '') => {
    const breadcrumb = 
      parentName === '' ?
      category.Category.name :
      `${parentName} > ${category.Category.name}`
  
    let mappedCategory = {
      breadcrumb,
      id: Number(category.Category.id),
      name: category.Category.name,
      subcategories: []
    }
  
    if(category.Category.children && category.Category.children.length > 0) {
      category.Category.children.forEach(child => {
        let mappedSubcategory = mapTrayCategory(child, breadcrumb);
        mappedCategory.subcategories.push(mappedSubcategory);
      })
  
      // Sort subcategories by name
      mappedCategory.subcategories.sort((a,b) => { return a.name.localeCompare(b.name) });
    }
  
    return mappedCategory;
  }
  
  export const mapTrayCategories = (categories) => {
    let mappedCategories = categories.reduce((result, category) => {
      result.push(mapTrayCategory(category));
      return result;
    }, []);
  
    // Sort categories by name
    mappedCategories.sort((a,b) => { return a.name.localeCompare(b.name) });
  
    return mappedCategories;
  };

  export const flatMappedCategories = (categories, sortBy = 'breadcrumb') => {
    let a = [];
    for(let i = 0; i < categories.length; i++){
      let o = categories[i];
      if(o.subcategories){
        var c = flatMappedCategories(o.subcategories);
        if(c){
            a = a.concat(c);
        }
      }
      a.push(o)
    }
    
    return a.sort((a,b) => { return a[sortBy].localeCompare(b[sortBy]) });
  }