import GoogleAdsStep from "./components/GoogleAdsStep";
import CampaignStep from "./components/CampaignStep";
import PresentationStep from "./components/PresentationStep";

import { useWizard } from "../../hooks/useWizard";
import ResumeStep from "./components/ResumeStep";

import { useAuth } from "../../hooks/useAuth";
import styles from "./styles.module.scss";

import Header from "./components/Header";
import Steps from "./components/Steps";

import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

function Pmax() {
  const auth = useAuth();
  const location = useLocation();

  const [step, setStep] = useState(0);
  const [stepsComponents, setStepsComponents] = useState([CampaignStep, GoogleAdsStep, ResumeStep]);
  const [firstCampaign, setFirstCampaign] = useState(location?.state?.firstCampaign || false);

  useEffect(() => {
    if (firstCampaign && stepsComponents.length < 4) {
      setStepsComponents([PresentationStep, ...stepsComponents]);
    }
  }, [firstCampaign, stepsComponents]);

  const wizard = useWizard();
  const DefaultStep = () => <></>;

  const StepComponent = stepsComponents[step] || DefaultStep;

  //Cleaning all saved data when user enters at pmax page
  useEffect(() => {
    return () => {
      localStorage.removeItem("tray-campaign-creation-data");
      localStorage.removeItem("tray-campaign-creation-data-filter");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard - Traygle Criar Campanha</title>
        <meta name="description" content="Dashboard Traygle" />
        <meta property="og:title" content="Dashboard - Traygle" />
        <meta property="og:description" content="Dashboard Traygle" />
        <script>
          {`
            (function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://data.tray.com.br/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-W9BVK5');
          `}
        </script>
      </Helmet>

      <Header displayCloseBtn={firstCampaign ? false : true} />

      <Steps step={step} showPresentation={stepsComponents.length === 4 ? true : false} />

      <div className={styles.campaign_container} key={stepsComponents.length}>
        <StepComponent auth={auth} setStep={setStep} step={step} wizard={wizard} reset={() => setStep(0)} editPaymentAgain={() => setStep(1)} />
      </div>
    </>
  );
}

export default Pmax;
